import { defineTokens } from '@pandacss/dev';
import { toTokens } from '~src/utils/panda/toTokens';
import { mix } from '~src/utils/polish';

export const colors = {
  // core grayscale colors
  'white': '#FFFFFF',
  'offWhite': '#F8F7F2',
  'black': '#0E0F13',
  'blackPure': '#000000',

  // brand
  'brand': '#EF7845',

  'gray.100': '#F3F1E9',
  'gray.200': '#EAE4DC',
  'gray.300': '#D4CBBF',
  'gray.400': '#A49E98',
  'gray.500': '#8D8782',
  'gray.600': '#6B6259',
  'gray.700': '#4F473F',
  'gray.800': '#2D2A26',
  'gray.900': '#0E0F13',

  // focus
  // focusBlue: '#2081E2',
  // softFocusBlue: 'rgba(32, 129, 226, 0.4)',

  'url': 'rgb(59, 130, 246)',

  // subBrands
  'blockverse': '#E86553',
  'blockverse.weak': '#FDBDAF',
  'blockverse.text': '#E65535',

  // UI
  'background': '#F8F7F2',
  'warning': '#FEEBC8',
  'warning.background': mix(0.75, '#FEEBC8', '#DD6B20'),
  'warning.strong': '#DD6B20',
  'success': '#C6F6D5',
  'success.background': mix(0.75, '#C6F6D5', '#38A169'),
  'success.strong': '#38A169',
  'error': '#FED7D7',
  'error.background': mix(0.75, '#FED7D7', '#E53E3E'),
  'error.strong': '#E53E3E',

  // accents
  'mint': '#DCE3AE',
  'coffee': '#E1C9B4',
  'lavender': '#B7BAEC',
  'turquoise': '#C4D7D9',
  'mint.strong': '#84926F',
  'coffee.strong': '#8F745D',
  'lavender.strong': '#6E71A4',
  'turquoise.strong': '#88A3A5',
  'mint.text': '#525947',
  'coffee.text': '#5E5146',
  'lavender.text': '#55576B',
  'turquoise.text': '#50686B',

  // darken
  'darken.075': 'rgba(0,0,0,0.075)',
  'darken': 'rgba(0,0,0,0.05)',
  'darken.05': 'rgba(0,0,0,0.05)',
  'darken.03': 'rgba(0,0,0,0.03)',
  'darken.015': 'rgba(0,0,0,0.015)',
  'darken.01': 'rgba(0,0,0,0.01)',
  'darken.35': 'rgba(0,0,0,0.35)',
  'darken.025': 'rgba(0,0,0,0.025)',

  // lighten
  'lighten.095': 'rgba(255,255,255,0.095)',
  'lighten.03': 'rgba(255,255,255,0.03)',
  'lighten.07': 'rgba(255,255,255,0.07)',
  'lighten': 'rgba(255,255,255,0.07)',
  'lighten.05': 'rgba(255,255,255,0.05)',
  'semiTransparentLighten': 'rgba(255,255,255,0.6)',

  'white-pane.4': `rgba(255, 255, 255, 0.4)`,
  'white-pane.6': `rgba(255, 255, 255, 0.6)`,
  'white-pane': `rgba(255, 255, 255, 0.6)`,
  'white-pane.75': `rgba(255, 255, 255, 0.75)`,
} as const;

export type Colors = keyof typeof colors;

export const brandColors = {
  opensea: '#2081E2',
  zerion: '#000000',
  zora: '#000000',
  looksRare: 'rgb(12, 228, 102)',
  x2y2: '#5730C3',
  zapper: '#774BFD',
  magicEden: '#E42575',

  base: '#3773F5',
  homestead: '#383D52',
  optimism: '#FF0420',
  bitcoin: '#F7931A',
  farcaster: '#8A63D2',
  lukso: '#FF2975',
} as const;

export type BrandColors = keyof typeof brandColors;

export const allColors = {
  ...colors,
  ...brandColors,
  transparent: 'transparent',
  inherit: 'inherit',
} as const;

export type AllColors = keyof typeof allColors;

export const colorsForPanda = defineTokens.colors(toTokens(allColors));
