import { Chain } from 'viem';
import { type CustomChainProps } from '../chains';

export const addCustomProps = <
  T extends Chain,
  CustomChainPropsConst extends CustomChainProps,
>(
  chain: T,
  props: CustomChainPropsConst,
) => {
  return {
    ...chain,
    custom: {
      ...props,
    } satisfies CustomChainProps,
  };
};
