import { Remote, wrap } from 'comlink';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  HiResWorkerApi,
  HiraethImageWorkerApi,
  SigilWorkerApi,
} from '../types/worker';
import { logula } from '~src/utils/debug/consola';
type HiraethWorkersContextType = {
  sigilComlinkRef: React.MutableRefObject<Remote<SigilWorkerApi> | undefined>;
  hiResComlinkRef: React.MutableRefObject<Remote<HiResWorkerApi> | undefined>;
  imageComlinkRef: React.MutableRefObject<
    Remote<HiraethImageWorkerApi> | undefined
  >;
};

export const HiraethWorkersContext = createContext<HiraethWorkersContextType>({
  sigilComlinkRef: { current: undefined },
  hiResComlinkRef: { current: undefined },
  imageComlinkRef: { current: undefined },
});

export type HiraethWorkersProviderProps = {};

export const useHiraethWorkers = () => {
  const context = useContext(HiraethWorkersContext);
  if (!context) {
    throw new Error(
      'useHiraethWorkers must be used within a HiraethWorkersProvider',
    );
  }
  return context;
};

export const HiraethWorkersProvider = ({
  children,
}: HiraethWorkersProviderProps & {
  children: React.ReactNode;
}) => {
  const sigilWorkerRef = useRef<Worker>();
  const sigilComlinkRef = useRef<Remote<SigilWorkerApi>>();
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    sigilWorkerRef.current = new Worker(
      new URL('../workers/sigil.worker', import.meta.url),
      {
        type: 'module',
      },
    );
    sigilComlinkRef.current = wrap<SigilWorkerApi>(sigilWorkerRef.current);
    return () => {
      sigilWorkerRef.current?.terminate();
    };
  }, []);

  const hiResWorkerRef = useRef<Worker>();
  const hiResComlinkRef = useRef<Remote<HiResWorkerApi>>();
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    hiResWorkerRef.current = new Worker(
      new URL('../workers/hi-res.worker', import.meta.url),
      {
        type: 'module',
      },
    );
    hiResComlinkRef.current = wrap<HiResWorkerApi>(hiResWorkerRef.current);
    return () => {
      hiResWorkerRef.current?.terminate();
    };
  }, []);

  const imageWorkerRef = useRef<Worker>();
  const imageComlinkRef = useRef<Remote<HiraethImageWorkerApi>>();
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    logula.withTag('hiraeth.workers').info('loading image worker');
    imageWorkerRef.current = new Worker(
      new URL('../workers/hiraeth-image.worker', import.meta.url),
      {
        type: 'module',
      },
    );
    imageComlinkRef.current = wrap<HiraethImageWorkerApi>(
      imageWorkerRef.current,
    );
    return () => {
      imageWorkerRef.current?.terminate();
    };
  }, []);

  const state = useMemo(() => {
    return {
      sigilComlinkRef,
      hiResComlinkRef,
      imageComlinkRef,
      // contexts:
      //   contexts ??
      //   (!!contextsFromCart ? Object.values(contextsFromCart) : undefined),
      // providedContexts: contexts,
    };
  }, [
    sigilComlinkRef,
    imageComlinkRef,
    hiResComlinkRef,
    // nftOwnerResult,
    // legacyNftOwnerResult,
    // metadata,
    // hashMetadataRes,
    // contexts,
    // contextsFromCart,
  ]);

  return (
    <HiraethWorkersContext.Provider value={state}>
      {children}
    </HiraethWorkersContext.Provider>
  );
};
