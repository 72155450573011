import {
  AsyncStorage,
  experimental_createPersister,
} from '@tanstack/query-persist-client-core';
import { del, get, set } from 'idb-keyval';

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */

// interface AsyncStorage {
//   getItem: (key: string) => Promise<string | undefined | null>
//   setItem: (key: string, value: string) => Promise<unknown>
//   removeItem: (key: string) => Promise<void>
// }

export function createIDBPersister(prefix: string = 'reactQuery') {
  const storage = {
    getItem: async (key: string) => {
      return await get(key);
    },
    setItem: (key: string, value: string) => {
      set(key, value);
    },
    removeItem: async (key: string) => {
      await del(key);
    },
  } as AsyncStorage;

  return experimental_createPersister({
    storage,
    prefix,
    serialize: (v) => v as any,
    deserialize: (v) => v as any,
  });
}
