import { useMemo } from 'react';
import { useLinkedWalletsForPrivyUser } from './useWalletFromPrivy';


export const useEmbeddedWalletForPrivyUser = () => {
  const wallets = useLinkedWalletsForPrivyUser();
  return useMemo(() => {
    return wallets?.find((wallet) => wallet.connectorType === 'embedded');
  }, [wallets]);
};
