import { useEffect } from 'react';

export const useSyncStateAcrossTabs = (updateStore: () => void) => {
  useEffect(() => {
    document.addEventListener('visibilitychange', updateStore);
    window.addEventListener('focus', updateStore);
    return () => {
      document.removeEventListener('visibilitychange', updateStore);
      window.removeEventListener('focus', updateStore);
    };
  }, []);
};
