import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { SetStateFromHook } from '~src/types/common';

interface PrivyDialogsProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  setIsOpen: SetStateFromHook<boolean>;
}
const PrivyDialogsContext = createContext<PrivyDialogsProps | undefined>(
  undefined,
);

export const usePrivyDialogs = () => {
  const context = useContext(PrivyDialogsContext);
  if (!context) {
    throw new Error(
      'usePrivyDialogs must be used within a PrivyDialogsProvider',
    );
  }
  return context;
};

interface PrivyDialogsProviderProps {
  children: React.ReactNode;
}

export const PrivyDialogsProvider: React.FC<PrivyDialogsProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();

  const open = useCallback(() => {
    setIsOpen(true);
  }, [router.asPath]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const state = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      open,
      close,
    }),
    [isOpen, setIsOpen, open, close],
  );

  return (
    <PrivyDialogsContext.Provider value={state}>
      {children}
    </PrivyDialogsContext.Provider>
  );
};
