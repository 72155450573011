import { AvailableChainId } from '@protocol/chains';
import { produce } from 'immer';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DEFAULT_CHAIN } from '~src/constants/rpc';
import { isEqual, uniqWith } from '~src/utils/lodash';
import { type ViewHistoryCmdItem } from '../types/cmd';

const STORE_VERSION = '1.0.1';
const STORE_NAME = `cmd-${STORE_VERSION}`;

const MAX_SEARCH_HISTORY = 100;

export type SearchDataState = {
  currentChainId: AvailableChainId;
  viewHistory: ViewHistoryCmdItem[];
};

export type SearchModifiers = {
  setCurrentChainId: (chainId: AvailableChainId) => void;
  pushViewHistory: (viewHistory: ViewHistoryCmdItem) => void;
};

export type SearchState = SearchModifiers & SearchDataState;

export const useSearchSettings = create<SearchState>()(
  persist(
    (set, get) => ({
      currentChainId: DEFAULT_CHAIN.id,
      viewHistory: [],
      pushViewHistory: (vh: ViewHistoryCmdItem) => {
        set(
          produce((u) => {
            const { viewHistory } = u;
            const newViewHistory = uniqWith([vh, ...viewHistory], isEqual);
            if (newViewHistory.length > MAX_SEARCH_HISTORY) {
              newViewHistory.pop();
            }
            u.viewHistory = newViewHistory;
          }),
        );
      },
      setCurrentChainId: (chainId: AvailableChainId) => {
        set(
          produce((u) => {
            u.currentChainId = chainId;
          }),
        );
      },
    }),
    { name: STORE_NAME },
  ),
);
