import { DefaultSeo } from 'next-seo';
import {
  DEFAULT_SITE_DESCRIPTION,
  DEFAULT_SITE_TITLE,
  OG_DEFAULT_BANNER_LINK,
  SITE_TITLE_TEMPLATE,
  TWITTER_HANDLE,
} from '../../constants';

export const Seo = () => (
  <>
    <DefaultSeo
      defaultTitle={DEFAULT_SITE_TITLE}
      titleTemplate={SITE_TITLE_TEMPLATE}
      description={DEFAULT_SITE_DESCRIPTION}
      openGraph={{
        type: 'website',
        locale: 'en_US',
        // url: STUDIO_LINK,
        title: DEFAULT_SITE_TITLE,
        description: DEFAULT_SITE_DESCRIPTION,
        site_name: DEFAULT_SITE_TITLE,
        images: [
          {
            url: OG_DEFAULT_BANNER_LINK,
            alt: DEFAULT_SITE_TITLE,
          },
        ],
      }}
      twitter={{
        handle: TWITTER_HANDLE,
        site: TWITTER_HANDLE,
        cardType: 'summary_large_image',
      }}
    />
  </>
);
