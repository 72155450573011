import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

declare const globalThis: {
  localeInitialized: boolean;
} & typeof global;

if (!globalThis.localeInitialized) {
  TimeAgo.addDefaultLocale(en);
  globalThis.localeInitialized = true;
}

export const dummyTimeAgo = {
  dummy: () => {
    return 'dummy';
  },
};
// let timeAgoInstance: TimeAgo | null = null;

// const getTimeAgoInstance = () => {
//   if (!timeAgoInstance) {
//     timeAgoInstance = new TimeAgo('en-US');
//   }
//   return timeAgoInstance;
// };

// export const formatTimeAgo = (date: Date | number) => {
//   const timeAgo = getTimeAgoInstance();
//   return timeAgo.format(date);
// };
