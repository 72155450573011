import { useHotkeys } from 'react-hotkeys-hook';
import { useCmdContext } from '../providers/Cmd';

export const useCmdHotkeys = () => {
  const { isOpen, query, setQuery, setIsOpen, scope, scopeDepth, popScope } =
    useCmdContext();

  // const router = useRouter();
  useHotkeys(
    'mod+k',
    (e) => {
      e.preventDefault();
      setIsOpen((s) => !s);
    },
    {
      enableOnFormTags: ['input'],
    },
  );

  useHotkeys(
    'esc',
    (e) => {
      e.preventDefault();
      // if scope depth is at 1 close modal
      if (scopeDepth.length === 1) {
        setIsOpen(false);
      } else {
        popScope();
      }
    },
    {
      enableOnFormTags: ['input'],
    },
  );
  // useHotkeys(
  //   'Enter',
  //   (e) => {
  //     e.preventDefault();
  //     let entry: SearchEntryForUI | undefined;
  //     for (const group of scopeState.groups) {
  //       entry = group.results.find((e) => e.index === activeEntryRowIndex);
  //       if (entry) break;
  //     }
  //     if (!entry) return;
  //     if (!!entry.href) {
  //       router.push(entry.href);
  //       if (!entry.shouldShowModalAfterAction) {
  //         setIsModalOpen(false);
  //       }
  //     }
  //     if (!!entry.onTrigger) {
  //       entry.onTrigger().then(() => {
  //         if (!entry?.shouldShowModalAfterAction) {
  //           setIsModalOpen(false);
  //         }
  //       });
  //     }
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  //   [activeEntryRowIndex, scopeState],
  // );

  // useHotkeys(
  //   'ArrowUp',
  //   (e) => {
  //     e.preventDefault();
  //     setActiveEntryRowIndex((t) => {
  //       if (t === 0) return t;
  //       return t - 1;
  //     });
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  // );

  // useHotkeys(
  //   'ArrowDown',
  //   (e) => {
  //     e.preventDefault();
  //     setActiveEntryRowIndex((t) => {
  //       if (t === maxEntriesIndex) return t;
  //       return t + 1;
  //     });
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  //   [maxEntriesIndex],
  // );

  // useHotkeys(
  //   'esc',
  //   (e) => {
  //     e.preventDefault();
  //     setIsModalOpen(false);
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  // );

  // useHotkeys(
  //   'Shift+Enter',
  //   (e) => {
  //     e.preventDefault();
  //     if (!!query) return;
  //     handleExampleQuery();
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  //   [query, handleExampleQuery],
  // );

  // useHotkeys(
  //   'Shift+Backspace',
  //   (e) => {
  //     e.preventDefault();
  //     if (!!query) {
  //       setQuery('');
  //     } else if (scope !== 'index') {
  //       setScope('index');
  //     }
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  // );
};

export const useCmdHotkeysInScope = () => {
  const { isOpen, query, setQuery, setIsOpen, popScope, scopeDepth } =
    useCmdContext();

  // const router = useRouter();
  // useHotkeys(
  //   'backspace',
  //   (e) => {
  //     e.preventDefault();
  //     if (scopeDepth.length === 1) {
  //       return;
  //     } else {
  //       popScope();
  //     }
  //   },
  //   {
  //     enableOnFormTags: ['input'],
  //   },
  // );

  // useHotkeys(
  //   'Enter',
  //   (e) => {
  //     e.preventDefault();
  //     let entry: SearchEntryForUI | undefined;
  //     for (const group of scopeState.groups) {
  //       entry = group.results.find((e) => e.index === activeEntryRowIndex);
  //       if (entry) break;
  //     }
  //     if (!entry) return;
  //     if (!!entry.href) {
  //       router.push(entry.href);
  //       if (!entry.shouldShowModalAfterAction) {
  //         setIsModalOpen(false);
  //       }
  //     }
  //     if (!!entry.onTrigger) {
  //       entry.onTrigger().then(() => {
  //         if (!entry?.shouldShowModalAfterAction) {
  //           setIsModalOpen(false);
  //         }
  //       });
  //     }
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  //   [activeEntryRowIndex, scopeState],
  // );

  // useHotkeys(
  //   'ArrowUp',
  //   (e) => {
  //     e.preventDefault();
  //     setActiveEntryRowIndex((t) => {
  //       if (t === 0) return t;
  //       return t - 1;
  //     });
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  // );

  // useHotkeys(
  //   'ArrowDown',
  //   (e) => {
  //     e.preventDefault();
  //     setActiveEntryRowIndex((t) => {
  //       if (t === maxEntriesIndex) return t;
  //       return t + 1;
  //     });
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  //   [maxEntriesIndex],
  // );

  // useHotkeys(
  //   'esc',
  //   (e) => {
  //     e.preventDefault();
  //     setIsModalOpen(false);
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  // );

  // useHotkeys(
  //   'Shift+Enter',
  //   (e) => {
  //     e.preventDefault();
  //     if (!!query) return;
  //     handleExampleQuery();
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  //   [query, handleExampleQuery],
  // );

  // useHotkeys(
  //   'Shift+Backspace',
  //   (e) => {
  //     e.preventDefault();
  //     if (!!query) {
  //       setQuery('');
  //     } else if (scope !== 'index') {
  //       setScope('index');
  //     }
  //   },
  //   {
  //     enabled: isModalOpen,
  //     enableOnFormTags: ['input'],
  //   },
  // );
};
