// import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { http } from 'wagmi';
import { base, baseSepolia, mainnet, sepolia, lukso} from 'wagmi/chains';
// import { APP_NAME } from '../constants';
import { createConfig } from '@privy-io/wagmi';
import { EVM_RPC_URL } from '../constants/rpc';
import { createClientUPProvider } from '@lukso/up-provider';
import { injected } from 'wagmi/connectors';
import { logula } from '~src/utils/debug/consola';
// const projectId = DEFAULT_WC_PROJECT_ID ?? 'NO_PROJECT_ID_ADDED';

// export const wagmiConfig = createConfig({
export const wagmiConfig = createConfig({
  // appName: APP_NAME,
  // projectId,
  chains: [
    mainnet,
    sepolia,
    base,
    baseSepolia,
    lukso,
    // luksoTestnet,
    // until we formally support polygon we will silently add polygon for lens
    // polygon,
  ],
  transports: {
    [mainnet.id]: http(EVM_RPC_URL[mainnet.id]),
    [sepolia.id]: http(EVM_RPC_URL[sepolia.id]),
    [base.id]: http(EVM_RPC_URL[base.id]),
    [baseSepolia.id]: http(EVM_RPC_URL[baseSepolia.id]),
    [lukso.id]: http(EVM_RPC_URL[lukso.id]),
    // [polygon.id]: http(
    //   'https://polygon-mainnet.g.alchemy.com/v2/gLtGanwFwwgAnFCRcQJh2iIbIUAwyyyF',
    // ),
  },
  // connectors: [
  //   injected({
  //     target: () => {
  //       logula.withTag('DEBUG').info('target', window);
  //       return {
  //         id: 'up-provider-mini-app',
  //         name: 'UP Provider',
  //         provider(window) {
  //           console.log('watf')
  //           return (window as any).lukso as any;
  //         },
  //       };
  //     },
  //   }),
  // ],
  ssr: true,
});
