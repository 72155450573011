import type { Evm } from '@pob/shared';
import { usePrivy, type Wallet } from '@privy-io/react-auth';
import { useMemo } from 'react';
import { useExternalWalletsForPrivyUser } from './useExternalWalletsForPrivyUser';
import { useEmbeddedWalletForPrivyUser } from './useEmbeddedWalletForPrivyUser';

export const useBestWalletForPrivyUser = () => {
  const embeddedWallet = useEmbeddedWalletForPrivyUser();
  const externalWallet = useBestExternalWalletForPrivyUser();
  return useMemo(() => {
    if (!!externalWallet) {
      return externalWallet;
    }
    return embeddedWallet;
  }, [embeddedWallet, externalWallet]);
};

export const useBestExternalWalletForPrivyUser = () => {
  const externalWallets = useExternalWalletsForPrivyUser();
  return useMemo(() => {
    return externalWallets?.[0];
  }, [externalWallets]);
};

export const useLinkedWalletsForPrivyUser = () => {
  const { user } = usePrivy();
  return useMemo(() => {
    if (!user) {
      return undefined;
    }
    return user.linkedAccounts.filter(
      (account) => account.type === 'wallet',
    ) as unknown as (Wallet & { address: Evm.Address })[];
  }, [user]);
};


