import {
  PrivyProvider as PrivyProviderBase,
  usePrivy,
} from '@privy-io/react-auth';
import React, { useEffect, type FC } from 'react';
import { privyConfig } from '~src/clients/privy-app';
import { trpc } from '~src/clients/trpc-client';
import { env } from '~src/constants/env';
import { logula } from '~src/utils/debug/consola';
// interface PrivyProps {
//}
// const PrivyContext = createContext<PrivyProps | undefined>(undefined);

// export const usePrivy = () => {
//   const context = useContext(PrivyContext);
//   if (!context) {
//     throw new Error('usePrivy must be used within a PrivyProvider');
//   }
//   return context;
// };

interface PrivyProviderProps {
  children: React.ReactNode;
}

export const PrivyProvider: React.FC<PrivyProviderProps> = ({ children }) => {
  return (
    <PrivyProviderBase
      appId={env.NEXT_PUBLIC_PRIVY_APP_ID}
      config={privyConfig}
    >
      <PrivyMirrorEffect />
      {children}
    </PrivyProviderBase>
  );
};

const PrivyMirrorEffect: FC = () => {
  const { user } = usePrivy();
  const { mutate: mirrorUser } = trpc.user.mirror.useMutation();
  useEffect(() => {
    if (user) {
      logula.withTag('privy').info('mirroring user');
      mirrorUser({});
    }
  }, [user]);
  return null;
};
