import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { SetStateFromHook } from '~src/types/common';

interface HeaderProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  setIsOpen: SetStateFromHook<boolean>;
}
const HeaderContext = createContext<HeaderProps | undefined>(undefined);

export const useHeader = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }
  return context;
};

interface HeaderProviderProps {
  children: React.ReactNode;
}

export const HeaderProvider: React.FC<HeaderProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();

  const open = useCallback(() => {
    setIsOpen(true);
  }, [router.asPath]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const state = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      open,
      close,
    }),
    [isOpen, setIsOpen, open, close],
  );

  return (
    <HeaderContext.Provider value={state}>{children}</HeaderContext.Provider>
  );
};
