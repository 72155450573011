export const ID_STRING_DELIMITER = '\u200B_';

export const serializeId = (id: any[]) => id.join(ID_STRING_DELIMITER);

export const deserializeId = <T extends any[]>(
  id: string,
  process?: (id: any[]) => any[],
) => {
  const result = id.split(ID_STRING_DELIMITER) as T;
  return process ? (process(result) as T) : result;
};
