import { keccak256, toHex } from 'viem';
import type { LSP } from '../types';

export const getERC725DataKey = <
  KeyType extends LSP.ERC725DataKey = LSP.ERC725DataKey,
>(
  key: string,
) => {
  return keccak256(toHex(key)) as KeyType;
};
