import { getAccessToken } from '@privy-io/react-auth';
import { unstable_httpBatchStreamLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import superjson from 'superjson';
import { VERCEL_URL } from '~src/constants';
import { AppRouter } from '../server/trpc/routers';
import { queryClient } from './query';

function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';
  if (VERCEL_URL)
    // reference for vercel.com
    return `https://${VERCEL_URL}`;
  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3333}`;
}

export const trpc = createTRPCNext<AppRouter>({
  config(opts) {
    return {
      links: [
        unstable_httpBatchStreamLink({
          transformer: superjson,
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/v11/ssr
           **/
          url: `${getBaseUrl()}/api/trpc`,
          // You can pass any HTTP headers you wish here
          async headers() {
            return {
              Authorization: `Bearer ${(await getAccessToken()) || ''}`,
            };
          },
        }),
      ],
      queryClient,
    };
  },
  /**
   * @link https://trpc.io/docs/v11/ssr
   **/
  ssr: false,
  transformer: superjson,
});
