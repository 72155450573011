import { ChainRuntime } from '@pob/shared';
import { type ValueOf } from 'type-fest';
import {
  Chain,
  base,
  baseSepolia,
  lukso,
  luksoTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  sepolia,
} from 'viem/chains';
import { z } from 'zod';
import { addCustomProps } from '../utils';
import { bitcoin } from './custom/bitcoin';

export type CustomChainProps = {
  runtime: ChainRuntime;
  blockTime: number;
  networkName: string;
  externalNetworkName: string | undefined;
  shouldAddUniqueIdToRpcUrl: boolean;
  requireAddEthereumChainParameter: boolean;
  iconUrl: string | undefined;
};

export type ChainWithAdditional = Chain<undefined, CustomChainProps>;

export const CHAIN_RUNTIME_TO_CHAIN_LOOKUP = {
  evm: {
    [base.id]: addCustomProps(base, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'base',
      externalNetworkName: undefined,
      shouldAddUniqueIdToRpcUrl: true,
      requireAddEthereumChainParameter: false,
      iconUrl: undefined,
    } as const),
    [baseSepolia.id]: addCustomProps(baseSepolia, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'base-sepolia',
      externalNetworkName: undefined,
      shouldAddUniqueIdToRpcUrl: true,
      requireAddEthereumChainParameter: false,
      iconUrl: undefined,
    } as const),
    [lukso.id]: addCustomProps(lukso, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'lukso',
      externalNetworkName: undefined,
      shouldAddUniqueIdToRpcUrl: false,
      requireAddEthereumChainParameter: false,
      iconUrl: 'https://pob.studio/public/icons/chains/lukso.svg',
    } as const),
    [luksoTestnet.id]: addCustomProps(luksoTestnet, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'lukso-testnet',
      externalNetworkName: undefined,
      shouldAddUniqueIdToRpcUrl: true,
      requireAddEthereumChainParameter: false,
      iconUrl: 'https://pob.studio/public/icons/chains/lukso-testnet.svg',
    } as const),
    [mainnet.id]: addCustomProps(mainnet, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'homestead',
      externalNetworkName: 'ethereum',
      shouldAddUniqueIdToRpcUrl: true,
      requireAddEthereumChainParameter: false,
      iconUrl: undefined,
    } as const),
    [sepolia.id]: addCustomProps(sepolia, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'sepolia',
      externalNetworkName: undefined,
      shouldAddUniqueIdToRpcUrl: true,
      requireAddEthereumChainParameter: false,
      iconUrl: undefined,
    } as const),
    [optimism.id]: addCustomProps(optimism, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'optimism',
      externalNetworkName: undefined,
      shouldAddUniqueIdToRpcUrl: true,
      requireAddEthereumChainParameter: false,
      iconUrl: undefined,
    } as const),
    [optimismSepolia.id]: addCustomProps(optimismSepolia, {
      runtime: 'evm',
      blockTime: 10000,
      networkName: 'optimism-sepolia',
      externalNetworkName: undefined,
      shouldAddUniqueIdToRpcUrl: true,
      requireAddEthereumChainParameter: false,
      iconUrl: undefined,
    } as const),
  },
  btc: {
    [bitcoin.id]: bitcoin,
  },
  solana: {},
} as const satisfies Record<ChainRuntime, Record<number, Chain>>;

export const CHAIN_ID_TO_CHAIN_LOOKUP = {
  ...CHAIN_RUNTIME_TO_CHAIN_LOOKUP.evm,
  ...CHAIN_RUNTIME_TO_CHAIN_LOOKUP.btc,
} as const satisfies Record<number, Chain>;

export type AvailableChain = ValueOf<typeof CHAIN_ID_TO_CHAIN_LOOKUP>;

export type AvailableChainId<
  R extends ChainRuntime = ChainRuntime,
  TypeMap = typeof CHAIN_RUNTIME_TO_CHAIN_LOOKUP,
> =
  TypeMap extends Record<ChainRuntime, any>
    ? R extends keyof TypeMap
      ? keyof TypeMap[R]
      : never
    : 'ERROR: Unhandled ChainRuntime';

export type LookUpByChainId<
  T,
  R extends ChainRuntime = ChainRuntime,
  PartialAllowed extends boolean = false,
> = PartialAllowed extends true
  ? { [K in AvailableChainId<R>]?: T }
  : { [K in AvailableChainId<R>]: T };

export type AvailableChainNetwork<R extends ChainRuntime = ChainRuntime> =
  (typeof CHAIN_ID_TO_CHAIN_LOOKUP)[AvailableChainId<R>]['custom']['networkName'];

export const CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP = Object.fromEntries(
  Object.entries(CHAIN_ID_TO_CHAIN_LOOKUP).map(([, chain]) => [
    chain.custom.networkName,
    chain.id,
  ]),
) as Record<AvailableChainNetwork, AvailableChainId>;

export const CHAIN_ID_TO_NETWORK_LOOKUP = Object.fromEntries(
  Object.entries(CHAIN_ID_TO_CHAIN_LOOKUP).map(([, chain]) => [
    chain.id,
    chain.custom.networkName,
  ]),
) as Record<AvailableChainId, AvailableChainNetwork>;

export const chainNetworkZod = z.nativeEnum(CHAIN_ID_TO_NETWORK_LOOKUP);
export const availableChainZod = z.nativeEnum(CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP);
export const chainIdZod = availableChainZod;

export const availableChainIdByRuntimeZod = {
  evm: z.nativeEnum(
    Object.fromEntries(
      Object.entries(CHAIN_RUNTIME_TO_CHAIN_LOOKUP.evm).map(([, value]) => [
        value.custom.networkName,
        value.id,
      ]),
    ) as Record<AvailableChainNetwork<'evm'>, AvailableChainId<'evm'>>,
  ),
  btc: z.nativeEnum(
    Object.fromEntries(
      Object.entries(CHAIN_RUNTIME_TO_CHAIN_LOOKUP.btc).map(([, value]) => [
        value.custom.networkName,
        value.id,
      ]),
    ) as Record<AvailableChainNetwork<'btc'>, AvailableChainId<'btc'>>,
  ),
  solana: null,
} as const satisfies Record<ChainRuntime, any | null>;

export const HARDHAT_CHAIN_ID = 31337 as const;

const ONLY_LOCAL_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP = {
  ['hardhat']: HARDHAT_CHAIN_ID,
  ['localhost']: HARDHAT_CHAIN_ID, // ! test
} as const;

export const TESTING_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP = {
  ...ONLY_LOCAL_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP,
  ...CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP,
} as const;

// little hacky since we are mostly concerned with EVM
export type AvailableChainNetworkAndTesting<
  R extends ChainRuntime = ChainRuntime,
> =
  | AvailableChainNetwork<R>
  | (R extends 'evm'
      ? keyof typeof ONLY_LOCAL_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP
      : never);

export type AvailableChainIdAndTesting<R extends ChainRuntime = ChainRuntime> =
  | AvailableChainId<R>
  | (R extends 'evm'
      ? (typeof ONLY_LOCAL_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP)[keyof typeof ONLY_LOCAL_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP]
      : never);
