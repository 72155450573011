import { z } from 'zod';
import type { Evm, HexString } from './types';
// import type { FarcasterCastHash } from '~src/external/farcaster/types';

const HEX_STRING_REGEX = /^0x([A-Fa-f0-9]{2})*$/;
// ensure they past the regex test
export const hexStringZod = z.custom<HexString>(
  (val) => typeof val === 'string' && HEX_STRING_REGEX.test(val),
  'Invalid hex string',
);

export const evmTxnHashZod = hexStringZod.refine(
  (val): val is Evm.TxnHash => val.length === 66,
  'Invalid transaction hash',
);

export const evmAddressZod = hexStringZod.refine(
  (val): val is Evm.Address => val.length === 42,
  'Invalid address',
);

export const evmSelectorZod = hexStringZod.refine(
  (val): val is Evm.Selector => val.length === 10,
  'Invalid selector',
);

// export const castHashZod = hexStringZod.refine(
//   (val): val is FarcasterCastHash => val.length === 20,
//   'Invalid cast hash',
// );

export const hexStringToSelectorZod = hexStringZod.transform((val) => {
  return val.slice(0, 10) as Evm.Selector;
});
