const tokens = {
  "animations.backdrop-in": {
    "value": "fade-in 250ms var(--easings-emphasized-in)",
    "variable": "var(--animations-backdrop-in)"
  },
  "animations.backdrop-out": {
    "value": "fade-out 200ms var(--easings-emphasized-out)",
    "variable": "var(--animations-backdrop-out)"
  },
  "animations.dialog-in": {
    "value": "scale-in 400ms var(--easings-emphasized-in)",
    "variable": "var(--animations-dialog-in)"
  },
  "animations.dialog-out": {
    "value": "scale-out 200ms var(--easings-emphasized-out)",
    "variable": "var(--animations-dialog-out)"
  },
  "animations.lightly-breath": {
    "value": "lightly-breath 1.4s ease-in-out infinite",
    "variable": "var(--animations-lightly-breath)"
  },
  "animations.breath": {
    "value": "breathe 1.8s ease-in-out infinite",
    "variable": "var(--animations-breath)"
  },
  "animations.fade-in": {
    "value": "fade-in 1s var(--easings-emphasized-in)",
    "variable": "var(--animations-fade-in)"
  },
  "animations.fade-out": {
    "value": "fade-out 1s var(--easings-emphasized-out)",
    "variable": "var(--animations-fade-out)"
  },
  "animations.slide-in": {
    "value": "slide-in 1s var(--easings-emphasized-in)",
    "variable": "var(--animations-slide-in)"
  },
  "animations.slide-out": {
    "value": "slide-out 1s var(--easings-emphasized-out)",
    "variable": "var(--animations-slide-out)"
  },
  "animations.subtle-slide-in": {
    "value": "subtle-slide-in 1s var(--easings-emphasized-in)",
    "variable": "var(--animations-subtle-slide-in)"
  },
  "animations.subtle-slide-out": {
    "value": "subtle-slide-out 1s var(--easings-emphasized-out)",
    "variable": "var(--animations-subtle-slide-out)"
  },
  "animations.fading-upward-nod": {
    "value": "fading-upward-nod 3s ease-in-out infinite",
    "variable": "var(--animations-fading-upward-nod)"
  },
  "animations.fading-downward-nod": {
    "value": "fading-downward-nod 3s ease-in-out infinite",
    "variable": "var(--animations-fading-downward-nod)"
  },
  "animations.fading-leftward-nod": {
    "value": "fading-leftward-nod 3s ease-in-out infinite",
    "variable": "var(--animations-fading-leftward-nod)"
  },
  "animations.fading-rightward-nod": {
    "value": "fading-rightward-nod 3s ease-in-out infinite",
    "variable": "var(--animations-fading-rightward-nod)"
  },
  "colors.white": {
    "value": "#FFFFFF",
    "variable": "var(--colors-white)"
  },
  "colors.offWhite": {
    "value": "#F8F7F2",
    "variable": "var(--colors-off-white)"
  },
  "colors.black": {
    "value": "#0E0F13",
    "variable": "var(--colors-black)"
  },
  "colors.blackPure": {
    "value": "#000000",
    "variable": "var(--colors-black-pure)"
  },
  "colors.brand": {
    "value": "#EF7845",
    "variable": "var(--colors-brand)"
  },
  "colors.gray.100": {
    "value": "#F3F1E9",
    "variable": "var(--colors-gray\\.100)"
  },
  "colors.gray.200": {
    "value": "#EAE4DC",
    "variable": "var(--colors-gray\\.200)"
  },
  "colors.gray.300": {
    "value": "#D4CBBF",
    "variable": "var(--colors-gray\\.300)"
  },
  "colors.gray.400": {
    "value": "#A49E98",
    "variable": "var(--colors-gray\\.400)"
  },
  "colors.gray.500": {
    "value": "#8D8782",
    "variable": "var(--colors-gray\\.500)"
  },
  "colors.gray.600": {
    "value": "#6B6259",
    "variable": "var(--colors-gray\\.600)"
  },
  "colors.gray.700": {
    "value": "#4F473F",
    "variable": "var(--colors-gray\\.700)"
  },
  "colors.gray.800": {
    "value": "#2D2A26",
    "variable": "var(--colors-gray\\.800)"
  },
  "colors.gray.900": {
    "value": "#0E0F13",
    "variable": "var(--colors-gray\\.900)"
  },
  "colors.url": {
    "value": "rgb(59, 130, 246)",
    "variable": "var(--colors-url)"
  },
  "colors.blockverse": {
    "value": "#E86553",
    "variable": "var(--colors-blockverse)"
  },
  "colors.blockverse.weak": {
    "value": "#FDBDAF",
    "variable": "var(--colors-blockverse\\.weak)"
  },
  "colors.blockverse.text": {
    "value": "#E65535",
    "variable": "var(--colors-blockverse\\.text)"
  },
  "colors.background": {
    "value": "#F8F7F2",
    "variable": "var(--colors-background)"
  },
  "colors.warning": {
    "value": "#FEEBC8",
    "variable": "var(--colors-warning)"
  },
  "colors.warning.background": {
    "value": "#f5cb9e",
    "variable": "var(--colors-warning\\.background)"
  },
  "colors.warning.strong": {
    "value": "#DD6B20",
    "variable": "var(--colors-warning\\.strong)"
  },
  "colors.success": {
    "value": "#C6F6D5",
    "variable": "var(--colors-success)"
  },
  "colors.success.background": {
    "value": "#a2e0ba",
    "variable": "var(--colors-success\\.background)"
  },
  "colors.success.strong": {
    "value": "#38A169",
    "variable": "var(--colors-success\\.strong)"
  },
  "colors.error": {
    "value": "#FED7D7",
    "variable": "var(--colors-error)"
  },
  "colors.error.background": {
    "value": "#f7b0b0",
    "variable": "var(--colors-error\\.background)"
  },
  "colors.error.strong": {
    "value": "#E53E3E",
    "variable": "var(--colors-error\\.strong)"
  },
  "colors.mint": {
    "value": "#DCE3AE",
    "variable": "var(--colors-mint)"
  },
  "colors.coffee": {
    "value": "#E1C9B4",
    "variable": "var(--colors-coffee)"
  },
  "colors.lavender": {
    "value": "#B7BAEC",
    "variable": "var(--colors-lavender)"
  },
  "colors.turquoise": {
    "value": "#C4D7D9",
    "variable": "var(--colors-turquoise)"
  },
  "colors.mint.strong": {
    "value": "#84926F",
    "variable": "var(--colors-mint\\.strong)"
  },
  "colors.coffee.strong": {
    "value": "#8F745D",
    "variable": "var(--colors-coffee\\.strong)"
  },
  "colors.lavender.strong": {
    "value": "#6E71A4",
    "variable": "var(--colors-lavender\\.strong)"
  },
  "colors.turquoise.strong": {
    "value": "#88A3A5",
    "variable": "var(--colors-turquoise\\.strong)"
  },
  "colors.mint.text": {
    "value": "#525947",
    "variable": "var(--colors-mint\\.text)"
  },
  "colors.coffee.text": {
    "value": "#5E5146",
    "variable": "var(--colors-coffee\\.text)"
  },
  "colors.lavender.text": {
    "value": "#55576B",
    "variable": "var(--colors-lavender\\.text)"
  },
  "colors.turquoise.text": {
    "value": "#50686B",
    "variable": "var(--colors-turquoise\\.text)"
  },
  "colors.darken.075": {
    "value": "rgba(0,0,0,0.075)",
    "variable": "var(--colors-darken\\.075)"
  },
  "colors.darken": {
    "value": "rgba(0,0,0,0.05)",
    "variable": "var(--colors-darken)"
  },
  "colors.darken.05": {
    "value": "rgba(0,0,0,0.05)",
    "variable": "var(--colors-darken\\.05)"
  },
  "colors.darken.03": {
    "value": "rgba(0,0,0,0.03)",
    "variable": "var(--colors-darken\\.03)"
  },
  "colors.darken.015": {
    "value": "rgba(0,0,0,0.015)",
    "variable": "var(--colors-darken\\.015)"
  },
  "colors.darken.01": {
    "value": "rgba(0,0,0,0.01)",
    "variable": "var(--colors-darken\\.01)"
  },
  "colors.darken.35": {
    "value": "rgba(0,0,0,0.35)",
    "variable": "var(--colors-darken\\.35)"
  },
  "colors.darken.025": {
    "value": "rgba(0,0,0,0.025)",
    "variable": "var(--colors-darken\\.025)"
  },
  "colors.lighten.095": {
    "value": "rgba(255,255,255,0.095)",
    "variable": "var(--colors-lighten\\.095)"
  },
  "colors.lighten.03": {
    "value": "rgba(255,255,255,0.03)",
    "variable": "var(--colors-lighten\\.03)"
  },
  "colors.lighten.07": {
    "value": "rgba(255,255,255,0.07)",
    "variable": "var(--colors-lighten\\.07)"
  },
  "colors.lighten": {
    "value": "rgba(255,255,255,0.07)",
    "variable": "var(--colors-lighten)"
  },
  "colors.lighten.05": {
    "value": "rgba(255,255,255,0.05)",
    "variable": "var(--colors-lighten\\.05)"
  },
  "colors.semiTransparentLighten": {
    "value": "rgba(255,255,255,0.6)",
    "variable": "var(--colors-semi-transparent-lighten)"
  },
  "colors.white-pane.4": {
    "value": "rgba(255, 255, 255, 0.4)",
    "variable": "var(--colors-white-pane\\.4)"
  },
  "colors.white-pane.6": {
    "value": "rgba(255, 255, 255, 0.6)",
    "variable": "var(--colors-white-pane\\.6)"
  },
  "colors.white-pane": {
    "value": "rgba(255, 255, 255, 0.6)",
    "variable": "var(--colors-white-pane)"
  },
  "colors.white-pane.75": {
    "value": "rgba(255, 255, 255, 0.75)",
    "variable": "var(--colors-white-pane\\.75)"
  },
  "colors.opensea": {
    "value": "#2081E2",
    "variable": "var(--colors-opensea)"
  },
  "colors.zerion": {
    "value": "#000000",
    "variable": "var(--colors-zerion)"
  },
  "colors.zora": {
    "value": "#000000",
    "variable": "var(--colors-zora)"
  },
  "colors.looksRare": {
    "value": "rgb(12, 228, 102)",
    "variable": "var(--colors-looks-rare)"
  },
  "colors.x2y2": {
    "value": "#5730C3",
    "variable": "var(--colors-x2y2)"
  },
  "colors.zapper": {
    "value": "#774BFD",
    "variable": "var(--colors-zapper)"
  },
  "colors.magicEden": {
    "value": "#E42575",
    "variable": "var(--colors-magic-eden)"
  },
  "colors.base": {
    "value": "#3773F5",
    "variable": "var(--colors-base)"
  },
  "colors.homestead": {
    "value": "#383D52",
    "variable": "var(--colors-homestead)"
  },
  "colors.optimism": {
    "value": "#FF0420",
    "variable": "var(--colors-optimism)"
  },
  "colors.bitcoin": {
    "value": "#F7931A",
    "variable": "var(--colors-bitcoin)"
  },
  "colors.farcaster": {
    "value": "#8A63D2",
    "variable": "var(--colors-farcaster)"
  },
  "colors.lukso": {
    "value": "#FF2975",
    "variable": "var(--colors-lukso)"
  },
  "colors.transparent": {
    "value": "transparent",
    "variable": "var(--colors-transparent)"
  },
  "colors.inherit": {
    "value": "inherit",
    "variable": "var(--colors-inherit)"
  },
  "gradients.whiteFadeBottomToUp": {
    "value": "linear-gradient(180deg, rgba(255,255,255,0) 0%, var(--colors-white) 90%, var(--colors-white) 100%)",
    "variable": "var(--gradients-white-fade-bottom-to-up)"
  },
  "gradients.whiteFadeUpToBottom": {
    "value": "linear-gradient(0deg, rgba(255,255,255,0) 0%, var(--colors-white) 90%, var(--colors-white) 100%)",
    "variable": "var(--gradients-white-fade-up-to-bottom)"
  },
  "gradients.gray200HorzPart": {
    "value": "linear-gradient(90deg, rgba(234, 228, 220,0) 0%, var(--colors-gray\\.200) 50%, rgba(234, 228, 220,0) 100%)",
    "variable": "var(--gradients-gray200-horz-part)"
  },
  "gradients.gray200VertPart": {
    "value": "linear-gradient(0deg, rgba(234, 228, 220,0) 0%, var(--colors-gray\\.200) 50%, rgba(234, 228, 220,0) 100%)",
    "variable": "var(--gradients-gray200-vert-part)"
  },
  "sizes.0": {
    "value": "0px",
    "variable": "var(--sizes-0)"
  },
  "sizes.1": {
    "value": "1px",
    "variable": "var(--sizes-1)"
  },
  "sizes.2": {
    "value": "2px",
    "variable": "var(--sizes-2)"
  },
  "sizes.4": {
    "value": "4px",
    "variable": "var(--sizes-4)"
  },
  "sizes.6": {
    "value": "6px",
    "variable": "var(--sizes-6)"
  },
  "sizes.8": {
    "value": "8px",
    "variable": "var(--sizes-8)"
  },
  "sizes.10": {
    "value": "10px",
    "variable": "var(--sizes-10)"
  },
  "sizes.squareIcon": {
    "value": "28px",
    "variable": "var(--sizes-square-icon)"
  },
  "sizes.navIsland": {
    "value": "40px",
    "variable": "var(--sizes-nav-island)"
  },
  "sizes.highlightSmallLine": {
    "value": "22px",
    "variable": "var(--sizes-highlight-small-line)"
  },
  "sizes.crestImg": {
    "value": "26px",
    "variable": "var(--sizes-crest-img)"
  },
  "sizes.pillHeight": {
    "value": "26px",
    "variable": "var(--sizes-pill-height)"
  },
  "sizes.navLogo": {
    "value": "32px",
    "variable": "var(--sizes-nav-logo)"
  },
  "sizes.largePfp": {
    "value": "36px",
    "variable": "var(--sizes-large-pfp)"
  },
  "sizes.mediumPfp": {
    "value": "28px",
    "variable": "var(--sizes-medium-pfp)"
  },
  "sizes.pfp": {
    "value": "24px",
    "variable": "var(--sizes-pfp)"
  },
  "sizes.subNav": {
    "value": "56px",
    "variable": "var(--sizes-sub-nav)"
  },
  "sizes.stickedSubNav": {
    "value": "64px",
    "variable": "var(--sizes-sticked-sub-nav)"
  },
  "sizes.nav": {
    "value": "72px",
    "variable": "var(--sizes-nav)"
  },
  "sizes.mobileNav": {
    "value": "52px",
    "variable": "var(--sizes-mobile-nav)"
  },
  "sizes.stickyTopFromSubNav": {
    "value": "88px",
    "variable": "var(--sizes-sticky-top-from-sub-nav)"
  },
  "sizes.luksoMiniDappWidth": {
    "value": "300px",
    "variable": "var(--sizes-lukso-mini-dapp-width)"
  },
  "sizes.luksoMinMiniDappHeight": {
    "value": "408px",
    "variable": "var(--sizes-lukso-min-mini-dapp-height)"
  },
  "sizes.xs": {
    "value": "500px",
    "variable": "var(--sizes-xs)"
  },
  "sizes.sm": {
    "value": "720px",
    "variable": "var(--sizes-sm)"
  },
  "sizes.md": {
    "value": "960px",
    "variable": "var(--sizes-md)"
  },
  "sizes.lg": {
    "value": "1280px",
    "variable": "var(--sizes-lg)"
  },
  "sizes.xl": {
    "value": "1500px",
    "variable": "var(--sizes-xl)"
  },
  "sizes.cmdDialog": {
    "value": "590px",
    "variable": "var(--sizes-cmd-dialog)"
  },
  "sizes.increment": {
    "value": "8px",
    "variable": "var(--sizes-increment)"
  },
  "sizes.threeQuartersIncrement": {
    "value": "6px",
    "variable": "var(--sizes-three-quarters-increment)"
  },
  "sizes.halfIncrement": {
    "value": "4px",
    "variable": "var(--sizes-half-increment)"
  },
  "sizes.quarterIncrement": {
    "value": "2px",
    "variable": "var(--sizes-quarter-increment)"
  },
  "sizes.halfThinGap": {
    "value": "1px",
    "variable": "var(--sizes-half-thin-gap)"
  },
  "sizes.nudge": {
    "value": "1px",
    "variable": "var(--sizes-nudge)"
  },
  "sizes.thinGap": {
    "value": "2px",
    "variable": "var(--sizes-thin-gap)"
  },
  "sizes.zero": {
    "value": "0px",
    "variable": "var(--sizes-zero)"
  },
  "sizes.fourExLargestBox": {
    "value": "128px",
    "variable": "var(--sizes-four-ex-largest-box)"
  },
  "sizes.threeExLargestBox": {
    "value": "96px",
    "variable": "var(--sizes-three-ex-largest-box)"
  },
  "sizes.twoExLargestBox": {
    "value": "64px",
    "variable": "var(--sizes-two-ex-largest-box)"
  },
  "sizes.onePointFiveExLargestBox": {
    "value": "48px",
    "variable": "var(--sizes-one-point-five-ex-largest-box)"
  },
  "sizes.largestBox": {
    "value": "32px",
    "variable": "var(--sizes-largest-box)"
  },
  "sizes.largeBox": {
    "value": "24px",
    "variable": "var(--sizes-large-box)"
  },
  "sizes.mediumBox": {
    "value": "20px",
    "variable": "var(--sizes-medium-box)"
  },
  "sizes.box": {
    "value": "16px",
    "variable": "var(--sizes-box)"
  },
  "sizes.innerBox": {
    "value": "12px",
    "variable": "var(--sizes-inner-box)"
  },
  "sizes.boxGap": {
    "value": "12px",
    "variable": "var(--sizes-box-gap)"
  },
  "sizes.ten": {
    "value": "10px",
    "variable": "var(--sizes-ten)"
  },
  "sizes.icon.twelve": {
    "value": "12px",
    "variable": "var(--sizes-icon-twelve)"
  },
  "sizes.icon.fourteen": {
    "value": "14px",
    "variable": "var(--sizes-icon-fourteen)"
  },
  "sizes.icon.sixteen": {
    "value": "16px",
    "variable": "var(--sizes-icon-sixteen)"
  },
  "sizes.icon.eighteen": {
    "value": "18px",
    "variable": "var(--sizes-icon-eighteen)"
  },
  "sizes.icon.twenty": {
    "value": "20px",
    "variable": "var(--sizes-icon-twenty)"
  },
  "sizes.icon.twentyFour": {
    "value": "24px",
    "variable": "var(--sizes-icon-twenty-four)"
  },
  "sizes.icon.badge": {
    "value": "32px",
    "variable": "var(--sizes-icon-badge)"
  },
  "sizes.icon.largeBadge": {
    "value": "40px",
    "variable": "var(--sizes-icon-large-badge)"
  },
  "sizes.100%": {
    "value": "100%",
    "variable": "var(--sizes-100\\%)"
  },
  "sizes.fit-content": {
    "value": "fit-content",
    "variable": "var(--sizes-fit-content)"
  },
  "sizes.breakpoint-xs": {
    "value": "500px",
    "variable": "var(--sizes-breakpoint-xs)"
  },
  "sizes.breakpoint-sm": {
    "value": "720px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "960px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "1500px",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-cmdDialog": {
    "value": "590px",
    "variable": "var(--sizes-breakpoint-cmd-dialog)"
  },
  "spacing.0": {
    "value": "0px",
    "variable": "var(--spacing-0)"
  },
  "spacing.1": {
    "value": "1px",
    "variable": "var(--spacing-1)"
  },
  "spacing.2": {
    "value": "2px",
    "variable": "var(--spacing-2)"
  },
  "spacing.4": {
    "value": "4px",
    "variable": "var(--spacing-4)"
  },
  "spacing.6": {
    "value": "6px",
    "variable": "var(--spacing-6)"
  },
  "spacing.8": {
    "value": "8px",
    "variable": "var(--spacing-8)"
  },
  "spacing.10": {
    "value": "10px",
    "variable": "var(--spacing-10)"
  },
  "spacing.increment": {
    "value": "8px",
    "variable": "var(--spacing-increment)"
  },
  "spacing.threeQuartersIncrement": {
    "value": "6px",
    "variable": "var(--spacing-three-quarters-increment)"
  },
  "spacing.halfIncrement": {
    "value": "4px",
    "variable": "var(--spacing-half-increment)"
  },
  "spacing.quarterIncrement": {
    "value": "2px",
    "variable": "var(--spacing-quarter-increment)"
  },
  "spacing.halfThinGap": {
    "value": "1px",
    "variable": "var(--spacing-half-thin-gap)"
  },
  "spacing.nudge": {
    "value": "1px",
    "variable": "var(--spacing-nudge)"
  },
  "spacing.thinGap": {
    "value": "2px",
    "variable": "var(--spacing-thin-gap)"
  },
  "spacing.zero": {
    "value": "0px",
    "variable": "var(--spacing-zero)"
  },
  "spacing.fourExLargestBox": {
    "value": "128px",
    "variable": "var(--spacing-four-ex-largest-box)"
  },
  "spacing.threeExLargestBox": {
    "value": "96px",
    "variable": "var(--spacing-three-ex-largest-box)"
  },
  "spacing.twoExLargestBox": {
    "value": "64px",
    "variable": "var(--spacing-two-ex-largest-box)"
  },
  "spacing.onePointFiveExLargestBox": {
    "value": "48px",
    "variable": "var(--spacing-one-point-five-ex-largest-box)"
  },
  "spacing.largestBox": {
    "value": "32px",
    "variable": "var(--spacing-largest-box)"
  },
  "spacing.largeBox": {
    "value": "24px",
    "variable": "var(--spacing-large-box)"
  },
  "spacing.mediumBox": {
    "value": "20px",
    "variable": "var(--spacing-medium-box)"
  },
  "spacing.box": {
    "value": "16px",
    "variable": "var(--spacing-box)"
  },
  "spacing.innerBox": {
    "value": "12px",
    "variable": "var(--spacing-inner-box)"
  },
  "spacing.boxGap": {
    "value": "12px",
    "variable": "var(--spacing-box-gap)"
  },
  "spacing.ten": {
    "value": "10px",
    "variable": "var(--spacing-ten)"
  },
  "fonts.display": {
    "value": "'Plus Jakarta Sans', sans-serif",
    "variable": "var(--fonts-display)"
  },
  "fonts.mono": {
    "value": "'Roboto Mono', monospace",
    "variable": "var(--fonts-mono)"
  },
  "fonts.inherit": {
    "value": "inherit",
    "variable": "var(--fonts-inherit)"
  },
  "fontSizes.button": {
    "value": "12px",
    "variable": "var(--font-sizes-button)"
  },
  "fontSizes.legacyLabel": {
    "value": "11px",
    "variable": "var(--font-sizes-legacy-label)"
  },
  "fontSizes.h1": {
    "value": "3rem",
    "variable": "var(--font-sizes-h1)"
  },
  "fontSizes.h1.mobile": {
    "value": "2.2rem",
    "variable": "var(--font-sizes-h1\\.mobile)"
  },
  "fontSizes.h2": {
    "value": "2rem",
    "variable": "var(--font-sizes-h2)"
  },
  "fontSizes.h2.mobile": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-h2\\.mobile)"
  },
  "fontSizes.h3": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-h3)"
  },
  "fontSizes.h3.mobile": {
    "value": "1.2rem",
    "variable": "var(--font-sizes-h3\\.mobile)"
  },
  "fontSizes.h3-5": {
    "value": "1.3rem",
    "variable": "var(--font-sizes-h3-5)"
  },
  "fontSizes.h4": {
    "value": "1.25rem",
    "variable": "var(--font-sizes-h4)"
  },
  "fontSizes.h4-5": {
    "value": "1rem",
    "variable": "var(--font-sizes-h4-5)"
  },
  "fontSizes.h5": {
    "value": "0.9rem",
    "variable": "var(--font-sizes-h5)"
  },
  "fontSizes.h6": {
    "value": "0.9em",
    "variable": "var(--font-sizes-h6)"
  },
  "fontSizes.p.display": {
    "value": "0.86rem",
    "variable": "var(--font-sizes-p\\.display)"
  },
  "fontSizes.p.display.unit": {
    "value": "0.8rem",
    "variable": "var(--font-sizes-p\\.display\\.unit)"
  },
  "fontSizes.p.display.caption": {
    "value": "0.76rem",
    "variable": "var(--font-sizes-p\\.display\\.caption)"
  },
  "fontSizes.p.display.reading": {
    "value": "0.9rem",
    "variable": "var(--font-sizes-p\\.display\\.reading)"
  },
  "fontSizes.p.display.med": {
    "value": "1rem",
    "variable": "var(--font-sizes-p\\.display\\.med)"
  },
  "fontSizes.p.display.large": {
    "value": "1.2rem",
    "variable": "var(--font-sizes-p\\.display\\.large)"
  },
  "fontSizes.p.mono.caption": {
    "value": "0.7rem",
    "variable": "var(--font-sizes-p\\.mono\\.caption)"
  },
  "fontSizes.p.mono": {
    "value": "0.8rem",
    "variable": "var(--font-sizes-p\\.mono)"
  },
  "fontWeights.display.thin": {
    "value": 500,
    "variable": "var(--font-weights-display-thin)"
  },
  "fontWeights.display.normal": {
    "value": 600,
    "variable": "var(--font-weights-display-normal)"
  },
  "fontWeights.display.bold": {
    "value": 700,
    "variable": "var(--font-weights-display-bold)"
  },
  "fontWeights.display.superBold": {
    "value": 800,
    "variable": "var(--font-weights-display-super-bold)"
  },
  "fontWeights.mono.thin": {
    "value": 400,
    "variable": "var(--font-weights-mono-thin)"
  },
  "fontWeights.mono.normal": {
    "value": 400,
    "variable": "var(--font-weights-mono-normal)"
  },
  "fontWeights.mono.bold": {
    "value": 500,
    "variable": "var(--font-weights-mono-bold)"
  },
  "fontWeights.mono.superBold": {
    "value": 600,
    "variable": "var(--font-weights-mono-super-bold)"
  },
  "fontWeights.inherit": {
    "value": "inherit",
    "variable": "var(--font-weights-inherit)"
  },
  "letterSpacings.button": {
    "value": "0.025rem",
    "variable": "var(--letter-spacings-button)"
  },
  "letterSpacings.navButton": {
    "value": "0.015rem",
    "variable": "var(--letter-spacings-nav-button)"
  },
  "lineHeights.1": {
    "value": "1",
    "variable": "var(--line-heights-1)"
  },
  "lineHeights.singleLine": {
    "value": "1",
    "variable": "var(--line-heights-single-line)"
  },
  "lineHeights.1.1": {
    "value": "1.1",
    "variable": "var(--line-heights-1\\.1)"
  },
  "lineHeights.1.2": {
    "value": "1.2",
    "variable": "var(--line-heights-1\\.2)"
  },
  "lineHeights.multiline": {
    "value": "1.65",
    "variable": "var(--line-heights-multiline)"
  },
  "lineHeights.multiline.compact": {
    "value": "1.3",
    "variable": "var(--line-heights-multiline-compact)"
  },
  "lineHeights.multiline.mono": {
    "value": "1.6",
    "variable": "var(--line-heights-multiline-mono)"
  },
  "radii.0": {
    "value": "0px",
    "variable": "var(--radii-0)"
  },
  "radii.1": {
    "value": "1px",
    "variable": "var(--radii-1)"
  },
  "radii.2": {
    "value": "2px",
    "variable": "var(--radii-2)"
  },
  "radii.4": {
    "value": "4px",
    "variable": "var(--radii-4)"
  },
  "radii.6": {
    "value": "6px",
    "variable": "var(--radii-6)"
  },
  "radii.8": {
    "value": "8px",
    "variable": "var(--radii-8)"
  },
  "radii.dropdown": {
    "value": "12px",
    "variable": "var(--radii-dropdown)"
  },
  "radii.innerDropdown": {
    "value": "6px",
    "variable": "var(--radii-inner-dropdown)"
  },
  "radii.box": {
    "value": "16px",
    "variable": "var(--radii-box)"
  },
  "radii.innerGap": {
    "value": "10px",
    "variable": "var(--radii-inner-gap)"
  },
  "radii.squaredButton": {
    "value": "8px",
    "variable": "var(--radii-squared-button)"
  },
  "radii.innerOfSquaredButton": {
    "value": "4px",
    "variable": "var(--radii-inner-of-squared-button)"
  },
  "radii.innerBox": {
    "value": "8px",
    "variable": "var(--radii-inner-box)"
  },
  "radii.badge": {
    "value": "6px",
    "variable": "var(--radii-badge)"
  },
  "radii.innerOfInnerBox": {
    "value": "4px",
    "variable": "var(--radii-inner-of-inner-box)"
  },
  "radii.pill": {
    "value": "999px",
    "variable": "var(--radii-pill)"
  },
  "radii.increment": {
    "value": "8px",
    "variable": "var(--radii-increment)"
  },
  "radii.threeQuartersIncrement": {
    "value": "6px",
    "variable": "var(--radii-three-quarters-increment)"
  },
  "radii.halfIncrement": {
    "value": "4px",
    "variable": "var(--radii-half-increment)"
  },
  "radii.quarterIncrement": {
    "value": "2px",
    "variable": "var(--radii-quarter-increment)"
  },
  "radii.halfThinGap": {
    "value": "1px",
    "variable": "var(--radii-half-thin-gap)"
  },
  "radii.nudge": {
    "value": "1px",
    "variable": "var(--radii-nudge)"
  },
  "radii.thinGap": {
    "value": "2px",
    "variable": "var(--radii-thin-gap)"
  },
  "radii.zero": {
    "value": "0px",
    "variable": "var(--radii-zero)"
  },
  "borderWidths.0": {
    "value": "0px",
    "variable": "var(--border-widths-0)"
  },
  "borderWidths.1": {
    "value": "1px",
    "variable": "var(--border-widths-1)"
  },
  "borderWidths.2": {
    "value": "2px",
    "variable": "var(--border-widths-2)"
  },
  "borderWidths.4": {
    "value": "4px",
    "variable": "var(--border-widths-4)"
  },
  "borderWidths.6": {
    "value": "6px",
    "variable": "var(--border-widths-6)"
  },
  "borderWidths.8": {
    "value": "8px",
    "variable": "var(--border-widths-8)"
  },
  "borderWidths.increment": {
    "value": "8px",
    "variable": "var(--border-widths-increment)"
  },
  "borderWidths.threeQuartersIncrement": {
    "value": "6px",
    "variable": "var(--border-widths-three-quarters-increment)"
  },
  "borderWidths.halfIncrement": {
    "value": "4px",
    "variable": "var(--border-widths-half-increment)"
  },
  "borderWidths.quarterIncrement": {
    "value": "2px",
    "variable": "var(--border-widths-quarter-increment)"
  },
  "borderWidths.halfThinGap": {
    "value": "1px",
    "variable": "var(--border-widths-half-thin-gap)"
  },
  "borderWidths.nudge": {
    "value": "1px",
    "variable": "var(--border-widths-nudge)"
  },
  "borderWidths.thinGap": {
    "value": "2px",
    "variable": "var(--border-widths-thin-gap)"
  },
  "borderWidths.zero": {
    "value": "0px",
    "variable": "var(--border-widths-zero)"
  },
  "shadows.none": {
    "value": "none",
    "variable": "var(--shadows-none)"
  },
  "shadows.superSoft": {
    "value": "0 1px 2px rgba(0, 0, 0, 0.005),\n  0 2px 4px rgba(0, 0, 0, 0.005),\n  0 4px 8px rgba(0, 0, 0, 0.005),\n  0 8px 16px rgba(0, 0, 0, 0.005),\n  0 16px 32px rgba(0, 0, 0, 0.005),\n  0 32px 64px rgba(0, 0, 0, 0.005)",
    "variable": "var(--shadows-super-soft)"
  },
  "shadows.soft": {
    "value": "0 1px 2px rgba(0, 0, 0, 0.01),\n         0 2px 4px rgba(0, 0, 0, 0.01),\n         0 4px 8px rgba(0, 0, 0, 0.01),\n         0 8px 16px rgba(0, 0, 0, 0.01),\n         0 16px 32px rgba(0, 0, 0, 0.01),\n         0 32px 64px rgba(0, 0, 0, 0.01)",
    "variable": "var(--shadows-soft)"
  },
  "shadows.default": {
    "value": "0 1px 2px rgba(0, 0, 0, 0.02),\n            0 2px 4px rgba(0, 0, 0, 0.02),\n            0 4px 8px rgba(0, 0, 0, 0.02),\n            0 8px 16px rgba(0, 0, 0, 0.02),\n            0 16px 32px rgba(0, 0, 0, 0.02),\n            0 32px 64px rgba(0, 0, 0, 0.02)",
    "variable": "var(--shadows-default)"
  },
  "shadows.strong": {
    "value": "0 1px 2px rgba(0, 0, 0, 0.02),\n            0 2px 4px rgba(0, 0, 0, 0.02),\n            0 4px 8px rgba(0, 0, 0, 0.02),\n            0 8px 16px rgba(0, 0, 0, 0.02),\n            0 16px 32px rgba(0, 0, 0, 0.02),\n            0 32px 64px rgba(0, 0, 0, 0.02)",
    "variable": "var(--shadows-strong)"
  },
  "easings.default": {
    "value": "cubic-bezier(0.2, 0.0, 0, 1.0)",
    "variable": "var(--easings-default)"
  },
  "easings.emphasized-in": {
    "value": "cubic-bezier(0.05, 0.7, 0.1, 1.0)",
    "variable": "var(--easings-emphasized-in)"
  },
  "easings.emphasized-out": {
    "value": "cubic-bezier(0.3, 0.0, 0.8, 0.15)",
    "variable": "var(--easings-emphasized-out)"
  },
  "opacity.0": {
    "value": 0,
    "variable": "var(--opacity-0)"
  },
  "opacity.5": {
    "value": 0.05,
    "variable": "var(--opacity-5)"
  },
  "opacity.10": {
    "value": 0.1,
    "variable": "var(--opacity-10)"
  },
  "opacity.20": {
    "value": 0.2,
    "variable": "var(--opacity-20)"
  },
  "opacity.30": {
    "value": 0.3,
    "variable": "var(--opacity-30)"
  },
  "opacity.40": {
    "value": 0.4,
    "variable": "var(--opacity-40)"
  },
  "opacity.50": {
    "value": 0.5,
    "variable": "var(--opacity-50)"
  },
  "opacity.60": {
    "value": 0.6,
    "variable": "var(--opacity-60)"
  },
  "opacity.70": {
    "value": 0.7,
    "variable": "var(--opacity-70)"
  },
  "opacity.80": {
    "value": 0.8,
    "variable": "var(--opacity-80)"
  },
  "opacity.90": {
    "value": 0.9,
    "variable": "var(--opacity-90)"
  },
  "opacity.100": {
    "value": 1,
    "variable": "var(--opacity-100)"
  },
  "zIndex.negative": {
    "value": -1,
    "variable": "var(--z-index-negative)"
  },
  "zIndex.zero": {
    "value": 0,
    "variable": "var(--z-index-zero)"
  },
  "zIndex.one": {
    "value": 1,
    "variable": "var(--z-index-one)"
  },
  "zIndex.two": {
    "value": 2,
    "variable": "var(--z-index-two)"
  },
  "zIndex.nav": {
    "value": 999,
    "variable": "var(--z-index-nav)"
  },
  "zIndex.floatingAction": {
    "value": 1000,
    "variable": "var(--z-index-floating-action)"
  },
  "zIndex.overlay": {
    "value": 1100,
    "variable": "var(--z-index-overlay)"
  },
  "zIndex.cursor": {
    "value": 1501,
    "variable": "var(--z-index-cursor)"
  },
  "zIndex.modal": {
    "value": 1200,
    "variable": "var(--z-index-modal)"
  },
  "zIndex.dropdown": {
    "value": 1300,
    "variable": "var(--z-index-dropdown)"
  },
  "zIndex.popover": {
    "value": 1300,
    "variable": "var(--z-index-popover)"
  },
  "zIndex.toast": {
    "value": 1500,
    "variable": "var(--z-index-toast)"
  },
  "aspectRatios.1:1": {
    "value": "1",
    "variable": "var(--aspect-ratios-1\\:1)"
  },
  "aspectRatios.crest": {
    "value": "40 / 12",
    "variable": "var(--aspect-ratios-crest)"
  },
  "aspectRatios.16:9": {
    "value": "16 / 9",
    "variable": "var(--aspect-ratios-16\\:9)"
  },
  "aspectRatios.hiraeth": {
    "value": "0.652173913",
    "variable": "var(--aspect-ratios-hiraeth)"
  },
  "breakpoints.xs": {
    "value": "500px",
    "variable": "var(--breakpoints-xs)"
  },
  "breakpoints.sm": {
    "value": "720px",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "960px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1280px",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "1500px",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.cmdDialog": {
    "value": "590px",
    "variable": "var(--breakpoints-cmd-dialog)"
  },
  "spacing.-0": {
    "value": "calc(var(--spacing-0) * -1)",
    "variable": "var(--spacing-0)"
  },
  "spacing.-1": {
    "value": "calc(var(--spacing-1) * -1)",
    "variable": "var(--spacing-1)"
  },
  "spacing.-2": {
    "value": "calc(var(--spacing-2) * -1)",
    "variable": "var(--spacing-2)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-6": {
    "value": "calc(var(--spacing-6) * -1)",
    "variable": "var(--spacing-6)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-10": {
    "value": "calc(var(--spacing-10) * -1)",
    "variable": "var(--spacing-10)"
  },
  "spacing.-increment": {
    "value": "calc(var(--spacing-increment) * -1)",
    "variable": "var(--spacing-increment)"
  },
  "spacing.-threeQuartersIncrement": {
    "value": "calc(var(--spacing-three-quarters-increment) * -1)",
    "variable": "var(--spacing-three-quarters-increment)"
  },
  "spacing.-halfIncrement": {
    "value": "calc(var(--spacing-half-increment) * -1)",
    "variable": "var(--spacing-half-increment)"
  },
  "spacing.-quarterIncrement": {
    "value": "calc(var(--spacing-quarter-increment) * -1)",
    "variable": "var(--spacing-quarter-increment)"
  },
  "spacing.-halfThinGap": {
    "value": "calc(var(--spacing-half-thin-gap) * -1)",
    "variable": "var(--spacing-half-thin-gap)"
  },
  "spacing.-nudge": {
    "value": "calc(var(--spacing-nudge) * -1)",
    "variable": "var(--spacing-nudge)"
  },
  "spacing.-thinGap": {
    "value": "calc(var(--spacing-thin-gap) * -1)",
    "variable": "var(--spacing-thin-gap)"
  },
  "spacing.-zero": {
    "value": "calc(var(--spacing-zero) * -1)",
    "variable": "var(--spacing-zero)"
  },
  "spacing.-fourExLargestBox": {
    "value": "calc(var(--spacing-four-ex-largest-box) * -1)",
    "variable": "var(--spacing-four-ex-largest-box)"
  },
  "spacing.-threeExLargestBox": {
    "value": "calc(var(--spacing-three-ex-largest-box) * -1)",
    "variable": "var(--spacing-three-ex-largest-box)"
  },
  "spacing.-twoExLargestBox": {
    "value": "calc(var(--spacing-two-ex-largest-box) * -1)",
    "variable": "var(--spacing-two-ex-largest-box)"
  },
  "spacing.-onePointFiveExLargestBox": {
    "value": "calc(var(--spacing-one-point-five-ex-largest-box) * -1)",
    "variable": "var(--spacing-one-point-five-ex-largest-box)"
  },
  "spacing.-largestBox": {
    "value": "calc(var(--spacing-largest-box) * -1)",
    "variable": "var(--spacing-largest-box)"
  },
  "spacing.-largeBox": {
    "value": "calc(var(--spacing-large-box) * -1)",
    "variable": "var(--spacing-large-box)"
  },
  "spacing.-mediumBox": {
    "value": "calc(var(--spacing-medium-box) * -1)",
    "variable": "var(--spacing-medium-box)"
  },
  "spacing.-box": {
    "value": "calc(var(--spacing-box) * -1)",
    "variable": "var(--spacing-box)"
  },
  "spacing.-innerBox": {
    "value": "calc(var(--spacing-inner-box) * -1)",
    "variable": "var(--spacing-inner-box)"
  },
  "spacing.-boxGap": {
    "value": "calc(var(--spacing-box-gap) * -1)",
    "variable": "var(--spacing-box-gap)"
  },
  "spacing.-ten": {
    "value": "calc(var(--spacing-ten) * -1)",
    "variable": "var(--spacing-ten)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar