import type { ChainWithAdditional } from '..';

export const bitcoin = {
  id: 1652135010, // ASCII value of "bitcoin"
  name: 'Bitcoin',
  nativeCurrency: {
    name: 'Bitcoin',
    symbol: 'BTC',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://go.getblock.io/YOU_NEED_YOUR_OWN_RPC_URL'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Mempool',
      url: 'https://mempool.space/',
    },
    ordinals: {
      name: 'Ordiscan',
      url: 'https://ordiscan.com/',
    },
  },
  custom: {
    runtime: 'btc',
    blockTime: 600000,
    networkName: 'bitcoin',
    externalNetworkName: undefined,
    shouldAddUniqueIdToRpcUrl: false,
    requireAddEthereumChainParameter: false,
    iconUrl: undefined,
  } as const,
} as const satisfies ChainWithAdditional;
