import { z } from 'zod';
import type { Evm } from '../types';
import { evmAddressZod } from '../zod';

export const extAccountUniversalProfileZod = z.object({
  userType: z.literal('universal-profile'),
  address: evmAddressZod,
});

export type ExtAccountUniversalProfile = Evm.ConvertHexStringsToEvmAddresses<
  z.infer<typeof extAccountUniversalProfileZod>
>;

export const extAccountFarcasterZod = z.object({
  userType: z.literal('farcaster'),
  fid: z.number(),
});

export type ExtAccountFarcasterZod = z.infer<typeof extAccountFarcasterZod>;

export const extAccountWalletZod = z.object({
  userType: z.literal('wallet'),
  address: evmAddressZod,
});

export type ExtAccountWallet = Evm.ConvertHexStringsToEvmAddresses<
  z.infer<typeof extAccountWalletZod>
>;

/**
 * External pointers represent unique identifiers for users on external protocols/social graphs
 * (e.g. Farcaster FID, Universal Profile address, or wallet address).
 * These are not the full user metadata, just the minimal info needed to identify a user.
 */
export const extAccountZod = z.union([
  extAccountUniversalProfileZod,
  extAccountFarcasterZod,
  extAccountWalletZod,
]);

/**
 * External pointers represent unique identifiers for users on external protocols/social graphs
 * (e.g. Farcaster FID, Universal Profile address, or wallet address).
 * These are not the full user metadata, just the minimal info needed to identify a user.
 */
export type ExtAccount = z.infer<typeof extAccountZod>;

export type ExtAccountType = Evm.ConvertHexStringsToEvmAddresses<
  ExtAccount['userType']
>;

export type ExtAccountTypeButOnlyAddressBased = Exclude<ExtAccountType, 'farcaster'>;
