import { useRouter } from 'next/router';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
// import { makeSearchNftQuery } from '../query/nft';
// import { makeSearchSagaQuery } from '../query/saga';
// import { makeSearchShuffleNftQuery } from '../query/shuffle-nft';
import { useCmdHotkeys, useCmdHotkeysInScope } from '../hooks/useCmdHotkeys';
// import { useContextsSearchScope } from '../scopes/contexts';

import { type CmdScopeContext } from '../types/scopes';

interface CmdContextProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  query: string;
  setQuery: Dispatch<SetStateAction<string>>;

  scope: CmdScopeContext;
  scopeDepth: CmdScopeContext[];
  pushScope: (scope: CmdScopeContext) => void;
  popScope: () => void;

  // entries: SearchEntryForUI[];
  // results: SearchEntryFromQuery[];
  // numQueriesLoading: number;

  // activeEntryRowIndex: number;
  // setActiveEntryRowIndex: Dispatch<SetStateAction<number>>;
  // maxEntriesIndex: number;
}
const CmdContext = createContext<CmdContextProps | undefined>(undefined);

export const useCmdContext = () => {
  const context = useContext(CmdContext);
  if (!context) {
    throw new Error('useCmdContext must be used within a CmdProvider');
  }
  return context;
};

interface CmdProviderProps {
  children: React.ReactNode;
}

const DEFAULT_SCOPE_CONTEXT: CmdScopeContext = {
  scope: 'index',
};

export const CmdProvider: React.FC<CmdProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  // useLockBodyScroll(isModalOpen);

  const [query, setQuery] = useState<string>('');
  const [scopeDepth, setScopeDepth] = useState<CmdScopeContext[]>([
    DEFAULT_SCOPE_CONTEXT,
  ]);

  const pushScope = useCallback((scope: CmdScopeContext) => {
    setScopeDepth((s) => [...s, scope]);
  }, []);

  const popScope = useCallback(() => {
    // do not pop if only one scope left
    setScopeDepth((s) => (s.length > 1 ? s.slice(0, s.length - 1) : s));
  }, []);

  const router = useRouter();

  const open = useCallback(() => {
    setIsOpen(true);
  }, [router.asPath]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const scope = useMemo(() => scopeDepth[scopeDepth.length - 1], [scopeDepth]);
  const state = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      open,
      close,

      query,
      setQuery,

      scopeDepth,
      scope,
      pushScope,
      popScope,
    }),
    [
      scopeDepth,
      pushScope,
      popScope,
      scope,
      isOpen,
      open,
      close,
      query,
      setQuery,
      setIsOpen,
    ],
  );

  return (
    <CmdContext.Provider value={state}>
      <CmdHotkeys />
      {children}
    </CmdContext.Provider>
  );
};

const CmdHotkeys: React.FC = () => {
  useCmdHotkeys();
  useCmdHotkeysInScope();
  return null;
};
