import consola, { createConsola, LogObject } from 'consola';
import { token } from '~src/styled-system/tokens';

const COMMON_TAGS = ['party-kit', 'privy', 'pob', 'blockverse-artifact', 'DEBUG', 'mini-app'];
export type CommonTag = (typeof COMMON_TAGS)[number];

const DEFAULT_TAG_MAP: Record<CommonTag, string> = {
  'party-kit': token('colors.turquoise.strong') as `#${string}`,
  privy: token('colors.coffee.strong') as `#${string}`,
  pob: token('colors.brand') as `#${string}`,
};

const TAG_COLORS: `#${string}`[] = [
  '#FFA07A', // Light salmon
  '#FFB6C1', // Light pink
  '#FFDAB9', // Peach puff
  '#E6E6FA', // Lavender
  '#B0E0E6', // Powder blue
  '#DDA0DD', // Plum
  '#FF69B4', // Hot pink
  '#AFEEEE', // Pale turquoise
  '#98FB98', // Pale green
  '#FFDAB9', // Peach puff
  '#D8BFD8', // Thistle
  '#90EE90', // Light green
  '#FF6347', // Tomato
  '#FF7F50', // Coral
  '#FFD700', // Gold
  '#ADFF2F', // Green yellow
  '#87CEEB', // Sky blue
  '#9370DB', // Medium purple
  '#FF69B4', // Hot pink
  '#40E0D0', // Turquoise
];

class PobBrowserReporter {
  options: any;
  defaultColor: string;
  levelColorMap: Record<number, string>;
  typeColorMap: Record<string, string>;
  tagColorMap: Record<string, string>;
  tagColorIndex: number;
  constructor(options: any) {
    this.options = { ...options };

    this.defaultColor = "#7f8c8d"; // Gray
    this.levelColorMap = {
      0: "#c0392b", // Red
      1: "#f39c12", // Yellow
      3: "#00BCD4", // Cyan
    };
    this.typeColorMap = {
      success: "#2ecc71", // Green
    };
    this.tagColorMap = {
      ...DEFAULT_TAG_MAP,
    };
    this.tagColorIndex = 0;
  }

  _getLogFn(level: number) {
    if (level < 1) {
      return console.error;
      // return (console as any).__error || console.error;
    }
    if (level === 1) {
      // return (console as any).__warn || console.warn;
      return console.warn;
    }
    return (console as any).__log || console.log;
  }

  log(logObj: LogObject) {
    const consoleLogFn = this._getLogFn(logObj.level);

    // Type
    const type = logObj.type === "log" ? "" : logObj.type;

    // Tag
    const tag = logObj.tag || "";

    // if we see a new tag, set the color
    if (!this.tagColorMap[tag]) {
      this.tagColorMap[tag] = TAG_COLORS[this.tagColorIndex];
      this.tagColorIndex = (this.tagColorIndex + 1) % TAG_COLORS.length;
    }

    // Styles
    const color = (!!tag && this.tagColorMap[tag]) ||
      // this.typeColorMap[logObj.type] ||
      // this.levelColorMap[logObj.level] ||
      this.defaultColor;

    const style = `
      background: ${color};
      border-radius: 0.5em;
      color: white;
      font-weight: bold;
      padding: 2px 0.5em;
    `;

    const badge = `%c${[tag, type].filter(Boolean).join(":")}`;

    // Log to the console
    if (typeof logObj.args[0] === "string") {
      // console.log('logObj', logObj, consoleLogFn, badge);
      consoleLogFn(
        `${badge}%c ${logObj.args[0]}`,
        style,
        // Empty string as style resets to default console style
        "",
        ...logObj.args.slice(1),
      );
    } else {
      consoleLogFn(badge, style, ...logObj.args);
    }
  }
}

export const logula = createConsola({
  reporters: [
    new PobBrowserReporter({}),
    // {
    //   log: (logObj) => {
    //     console.log(JSON.stringify(logObj));
    //   },
    // },
  ],
});