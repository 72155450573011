import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const kv = createEnv({
  server: {
    KV_REST_API_READ_ONLY_TOKEN: z.string().min(1),
    KV_REST_API_TOKEN: z.string().min(1),
    KV_REST_API_URL: z.string().url(),
    KV_URL: z.string().url(),
  },
  experimental__runtimeEnv: process.env,
});
