import { z } from 'zod';
import { partyUserPresenceZod, partyUserZod } from './user';

export const partySyncMessageZod = <T extends z.ZodType>(stateZod: T) =>
  z.object({
    type: z.literal('sync'),
    state: stateZod,
  });

export const partyPresenceSyncMessageZod = partySyncMessageZod(
  z.record(
    z.string(),
    z.object({
      presence: partyUserPresenceZod,
      user: partyUserZod,
      lastUpdated: z.number(),
    }),
  ),
);

export type PartyPresenceSyncMessage = z.infer<
  typeof partyPresenceSyncMessageZod
>;

export const partyPresenceUpdateClientToServerMessageZod = z.object({
  type: z.literal('position-presence-update'),
  presence: partyUserPresenceZod.nullable(),
});

export const partyUserUpdateClientToServerMessageZod = z.object({
  type: z.literal('user-update'),
  user: partyUserZod,
});

export type PartyPresenceUpdateClientToServerMessage = z.infer<
  typeof partyPresenceUpdateClientToServerMessageZod
>;

export type PartyUserUpdateClientToServerMessage = z.infer<
  typeof partyUserUpdateClientToServerMessageZod
>;

export const partyPresenceClientToServerMessagesZod = z.union([
  partyPresenceUpdateClientToServerMessageZod,
  partyUserUpdateClientToServerMessageZod,
]);

export const partyPresenceUpdateServerToClientMessageZod = z.object({
  type: z.literal('position-presence-update'),
  user: partyUserZod,
  presence: partyUserPresenceZod.nullable(),
  connectionId: z.string(),
});

export const partyPresenceServerToClientMessagesZod = z.union([
  partyPresenceUpdateServerToClientMessageZod,
  partyPresenceSyncMessageZod,
]);

export type PartyPresenceServerToClientMessage = z.infer<
  typeof partyPresenceServerToClientMessagesZod
>;
