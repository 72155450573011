import {
  CHAIN_ID_TO_CHAIN_LOOKUP,
  LookUpByChainId,
  bitcoin,
  type AvailableChain,
  type AvailableChainId,
} from '@protocol/chains';
import {
  base,
  baseSepolia,
  lukso,
  luksoTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  sepolia,
} from 'viem/chains';
import { withUniqueId } from '~src/utils/urls/uuid';
import { env } from './env';

export const ALCHEMY_API_KEY: LookUpByChainId<string | null, 'evm'> = {
  [mainnet.id]: env.NEXT_PUBLIC_MAINNET_ALCHEMY_KEY,
  [base.id]: env.NEXT_PUBLIC_MAINNET_ALCHEMY_KEY,
  [baseSepolia.id]: env.NEXT_PUBLIC_MAINNET_ALCHEMY_KEY,
  [sepolia.id]: env.NEXT_PUBLIC_MAINNET_ALCHEMY_KEY,
  [optimism.id]: env.NEXT_PUBLIC_MAINNET_ALCHEMY_KEY,
  [optimismSepolia.id]: env.NEXT_PUBLIC_MAINNET_ALCHEMY_KEY,
  [lukso.id]: null,
  [luksoTestnet.id]: null,
};

export const EVM_RPC_URL: LookUpByChainId<string | undefined> = {
  [base.id]:
    'https://base-mainnet.g.alchemy.com/v2/gLtGanwFwwgAnFCRcQJh2iIbIUAwyyyF',
  [baseSepolia.id]:
    'https://base-sepolia.g.alchemy.com/v2/gLtGanwFwwgAnFCRcQJh2iIbIUAwyyyF', // for sanity, we will just drop a public rpc here
  [mainnet.id]:
    'https://eth-mainnet.g.alchemy.com/v2/gLtGanwFwwgAnFCRcQJh2iIbIUAwyyyF',
  [sepolia.id]:
    'https://eth-sepolia.g.alchemy.com/v2/gLtGanwFwwgAnFCRcQJh2iIbIUAwyyyF',
  [optimism.id]:
    'https://opt-mainnet.g.alchemy.com/v2/gLtGanwFwwgAnFCRcQJh2iIbIUAwyyyF',
  [optimismSepolia.id]:
    'https://opt-sepolia.g.alchemy.com/v2/gLtGanwFwwgAnFCRcQJh2iIbIUAwyyyF',
  [bitcoin.id]: undefined,
  [lukso.id]:
    'https://enterprise.rpc.lukso.sigmacore.io/POBSTUDIOTemp0xfmfjedokfomdlfmeojkfolfojelmfkenojdlmfljep',
  [luksoTestnet.id]: 'https://rpc.testnet.lukso.network',
};

export const READ_ONLY_RPC_URL: LookUpByChainId<string | undefined> = {
  [base.id]: undefined,
  [baseSepolia.id]: undefined, // for sanity, we will just drop a public rpc here
  [mainnet.id]: undefined,
  [sepolia.id]: undefined,
  [optimism.id]: undefined,
  [optimismSepolia.id]: undefined,
  [bitcoin.id]: undefined,
  [lukso.id]: undefined,
  [luksoTestnet.id]: undefined,
  // [lukso.id]: 'https://lukso.rpc.hypersync.xyz',
};

export const getRpcUrl = (
  chainId: AvailableChainId,
  options: {
    isReadOnly?: boolean;
  } = {},
) => {
  const rpcUrl = options.isReadOnly
    ? READ_ONLY_RPC_URL[chainId] ?? EVM_RPC_URL[chainId]
    : EVM_RPC_URL[chainId];

  if (!rpcUrl) {
    return undefined;
  }

  const chain = CHAIN_ID_TO_CHAIN_LOOKUP[chainId];
  if (chain.custom.shouldAddUniqueIdToRpcUrl) {
    return withUniqueId(rpcUrl);
  }
  return rpcUrl;
};

export const DEFAULT_CHAIN: AvailableChain =
  CHAIN_ID_TO_CHAIN_LOOKUP[mainnet.id];

export const UNINITIALIZED_CHAIN = {
  ...mainnet,
  id: -1,
};
