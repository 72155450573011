import { z } from 'zod';
import { extAccountZod } from '../user';

export const partyUserPositionZod = z.object({
  x: z.number().min(0).max(1),
  y: z.number().min(0).max(1),
  pointer: z.enum(['mouse', 'touch']),
});

export type PartyUserPosition = z.infer<typeof partyUserPositionZod>;

export const partyUserPresenceZod = z.object({
  position: partyUserPositionZod,
  // lastUpdated: z.number(),
});

export type PartyUserPresence = z.infer<typeof partyUserPresenceZod>;

/**
 * Party users are external pointers to users on external protocols/social graphs
 * (e.g. Farcaster FID, Universal Profile address, or wallet address).
 */
export const partyUserZod = extAccountZod;

export type PartyUser = z.infer<typeof partyUserZod>;
