import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const qStash = createEnv({
  server: {
    QSTASH_TOKEN: z.string().min(1),
    QSTASH_URL: z.string().url(),
    QSTASH_CURRENT_SIGNING_KEY: z.string().min(1),
    QSTASH_NEXT_SIGNING_KEY: z.string().min(1),
  },
  experimental__runtimeEnv: process.env,
});
