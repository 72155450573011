import type { BlockverseProjectId } from '@pob/blockverse';
import type {
  BaseBlockverseObjId,
  BlockverseProjectDomain,
} from '~src/shared-apps/blockverse/types';

export const getBlockverseProjectDomainFromObjId = <
  E extends BlockverseProjectId = BlockverseProjectId,
>(
  objId: BaseBlockverseObjId<E>,
): BlockverseProjectDomain<E> => {
  return [objId[0], objId[1]];
};
