import type { Tagged, UnwrapTagged } from 'type-fest';
import type { Evm, LSP } from '../types';
import { getERC725ArrayDataKey } from './getERC725ArrayDataKey';

export const RAW_ERC725_VERIFIED_URL_METHODS = {
  'keccak256(utf8)': '0x6f357c6a',
  'keccak256(bytes)': '0x820464dd',
  'ecdsa': '0xac75a10e',
} as const;

export const ERC725_VERIFIED_URL_METHODS = Object.fromEntries(
  Object.entries(RAW_ERC725_VERIFIED_URL_METHODS).map(([method, signature]) => [
    method,
    signature as Evm.Selector<typeof signature>,
  ]),
) as {
  [key in keyof typeof RAW_ERC725_VERIFIED_URL_METHODS]: ERC725VerificationMethodSignature;
};

export const ERC725_METHOD_SIGNATURE_TO_METHOD = Object.fromEntries(
  Object.entries(RAW_ERC725_VERIFIED_URL_METHODS).map(([method, signature]) => [
    signature,
    method,
  ]),
) as Record<
  UnwrapTagged<ERC725VerificationMethodSignature>,
  ERC725VerificationMethod
>;

export type ERC725VerificationMethod =
  keyof typeof RAW_ERC725_VERIFIED_URL_METHODS;

export type ERC725VerificationMethodSignature = Tagged<
  Evm.Selector<
    (typeof RAW_ERC725_VERIFIED_URL_METHODS)[ERC725VerificationMethod]
  >,
  'erc725-verification-method-signature'
>;

export const COMMON_ERC725_DATA_KEYS = {
  LSP3Profile:
    '0x5ef83ad9559033e6e941db7d7c495acdce616347d28e90c7ce47cbfcfcad3bc5' as LSP.ERC725DataKey, // getERC725DataKey('LSP3Profile'),
  LSP4Metadata:
    '0x9afb95cacc9f95858ec44aa8c3b685511002e30ae54415823f406128b85b238e' as LSP.ERC725DataKey, // getERC725DataKey('LSP4Metadata'),
  LSP4Creators: getERC725ArrayDataKey('LSP4Creators[]').rootDataKey,
  LSP12IssuedAssets: getERC725ArrayDataKey('LSP12IssuedAssets[]').rootDataKey,
  // LSP18RoyaltiesRecipients: getERC725ArrayDataKey('LSP18RoyaltiesRecipients').rootDataKey,
};
