import type { ChainRuntime } from '@pob/shared';
import {
  AvailableChainId,
  AvailableChainIdAndTesting,
  AvailableChainNetwork,
  CHAIN_ID_TO_CHAIN_LOOKUP,
  CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP,
  CHAIN_RUNTIME_TO_CHAIN_LOOKUP,
  HARDHAT_CHAIN_ID,
  TESTING_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP,
  type AvailableChainNetworkAndTesting,
  type ChainWithAdditional,
} from '../chains';

export const isAvailableChainNetwork = (
  network: any,
): network is AvailableChainNetwork => {
  return !!(CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP as any)[network];
};

export function assertIsAvailableChainNetwork(
  network: any,
): asserts network is AvailableChainNetwork {
  if (!isAvailableChainNetwork(network)) {
    throw new Error(`Invalid network: ${network}`);
  }
}

export const isAvailableChainId = <R extends ChainRuntime = ChainRuntime>(
  id: any,
  runtime?: R,
): id is AvailableChainId<R> => {
  return (
    !!(CHAIN_ID_TO_CHAIN_LOOKUP as any)[id] &&
    (!runtime ||
      (
        (CHAIN_RUNTIME_TO_CHAIN_LOOKUP[runtime] as any)[
          id
        ] as ChainWithAdditional
      ).custom?.runtime === runtime)
  );
};

export function assertIsAvailableChainId<R extends ChainRuntime = ChainRuntime>(
  id: any,
  runtime?: R,
): asserts id is AvailableChainId<R> {
  if (!isAvailableChainId(id, runtime)) {
    throw new Error(`Invalid chain id: ${id}`);
  }
}

export const isAvailableChainIdAndTesting = <
  R extends ChainRuntime = ChainRuntime,
>(
  id: any,
  runtime?: R,
): id is AvailableChainIdAndTesting<R> => {
  return isAvailableChainId(id, runtime) || id === HARDHAT_CHAIN_ID;
};

export function assertIsAvailableChainIdAndTesting<
  R extends ChainRuntime = ChainRuntime,
>(id: any, runtime?: R): asserts id is AvailableChainIdAndTesting<R> {
  if (!isAvailableChainIdAndTesting(id, runtime)) {
    throw new Error(`Invalid chain id: ${id}`);
  }
}

export const isAvailableChainNetworkAndTesting = <
  R extends ChainRuntime = ChainRuntime,
>(
  network: any,
): network is AvailableChainNetworkAndTesting<R> => {
  return !!(TESTING_CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP as any)[network];
};

export function assertIsAvailableChainNetworkAndTesting<
  R extends ChainRuntime = ChainRuntime,
>(network: any): asserts network is AvailableChainNetworkAndTesting<R> {
  if (!isAvailableChainNetworkAndTesting(network)) {
    throw new Error(`Invalid network: ${network}`);
  }
}
