export const VERCEL_ENV = (process.env.NEXT_PUBLIC_VERCEL_ENV ??
  process.env.VERCEL_ENV) as 'development' | 'preview' | 'production';

export const IS_PROD = VERCEL_ENV === 'production';

export const VERCEL_URL = `https://${process.env.NEXT_PUBLIC_VERCEL_URL ?? process.env.VERCEL_URL}`;
export const LOCAL_URL = 'http://localhost:3333';
export const PRODUCTION_URL = 'https://pob.studio';
export const PRODUCTION_URL_WWW = 'https://www.pob.studio';
export const TUNNEL_URL = 'https://local.pob.studio/'; // 'https://tunnel.pob.studio';

export const ROOT_URL = (() => {
  if (VERCEL_ENV === 'production') return PRODUCTION_URL;
  if (VERCEL_ENV === 'preview') return VERCEL_URL;
  return LOCAL_URL;
})();

export const ROOT_URL_WITH_TUNNEL = (() => {
  if (VERCEL_ENV === 'production') return PRODUCTION_URL;
  if (VERCEL_ENV === 'preview') return VERCEL_URL;
  return TUNNEL_URL;
})();

export const DEFAULT_SITE_TITLE = 'Pob Studio';

export const SITE_TITLE_TEMPLATE = '%s | Pob Studio';

export const DEFAULT_SITE_DESCRIPTION =
  'An independent NFT production studio. Art productions exploring the limits of NFTs. We make art to express our ideas and explore our curiosities.';

export const APP_NAME = 'Pob';

export const BLOG_LINK = `https://pob.mirror.xyz`;

export const NEWSLETTER_LINK = `https://pob.mirror.xyz/subscribe`;

export const TWITTER_LINK = `https://twitter.com/prrfbeauty`;

export const THREADS_LINK = `https://www.threads.net/@prrfbeauty`;

export const DISCORD_LINK = `${ROOT_URL}/discord`;

//`https://discord.gg/pob`;

export const GITHUB_LINK = `https://github.com/proofofbeauty`;

export const WARPCAST_LINK = `https://warpcast.com/pobstudio`;

export const YOUTUBE_LINK = `https://www.youtube.com/@PobStudioYT`;

export const STUDIO_LINK = `https://pob.studio`;

export const WARPCAST_PARENT_CHANNEL_BASE_URL =
  'https://warpcast.com/~/channel/';

export const GITCOIN_LINK = ``;

export const PRESERVATION_GITHUB_LINK = `https://github.com/pobstudio/awesome-nft-preservation`;

export const OG_DEFAULT_BANNER_LINK = `${STUDIO_LINK}/og/default.png`;

export const TWITTER_HANDLE = '@prrfbeauty';

// export const ZORA_GRAPH_QL_API_URL = `https://api.zora.co/graphql`;

// export const NFT_PORT_API_URL = `https://api.nftport.xyz`;

export const IPFS_GATEWAY = 'https://ipfs.pob.studio';

export const PUBLIC_IPFS_GATEWAY = 'https://dweb.link';

export const OPEN_CHAIN_URL = 'https://api.openchain.xyz';

export const LENS_ONBOARD_URL = 'https://www.lens.xyz/';

export const LENS_PROFILE_URL = (handle: string) =>
  `https://hey.xyz/u/${handle}`;

export const UUID_NAMESPACE = 'ded2b846-29cc-4dbf-b28d-36ff3192f511';

export const APP_POSTGRES_URL = process.env.POSTGRES_URL;

export const PONDER_URL =  (() => {
  if (VERCEL_ENV === 'production')
    return 'https://ponder.pob.studio';
  if (VERCEL_ENV === 'preview') return 'https://ponder.pob.studio';
  return 'http://localhost:42069';
})();

// TODO: make this a variable with env
export const PARTY_KIT_URL = (() => {
  if (VERCEL_ENV === 'production')
    return 'pob-studio-party.dave4506.partykit.dev';
  if (VERCEL_ENV === 'preview') return 'pob-studio-party.dave4506.partykit.dev';
  return 'pob-studio-party.dave4506.partykit.dev';
  // return 'localhost:1999';
})();

export const EFP_URL = 'https://efp.app/';