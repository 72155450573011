import { z } from 'zod';

export const collectionLsp4MetadataZod = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  links: z.array(
    z.object({
      url: z.string(),
      title: z.string().optional(),
    }),
  ),
  images: z.array(
    z
      .object({
        url: z.string(),
        width: z.number(),
        height: z.number(),
      })
      .array(),
  ),
  icons: z
    .array(
      z
        .object({
          url: z.string(),
          width: z.number(),
          height: z.number(),
        })
        .array(),
    )
    .optional(),
});

export const wrappedCollectionLsp4MetadataZod = z.object({
  LSP4Metadata: collectionLsp4MetadataZod,
});

export type CollectionLsp4Metadata = z.infer<typeof collectionLsp4MetadataZod>;
