import { useMemo } from 'react';
import { useLinkedWalletsForPrivyUser } from './useWalletFromPrivy';


export const useExternalWalletsForPrivyUser = () => {
  const wallets = useLinkedWalletsForPrivyUser();
  return useMemo(() => {
    return wallets?.filter((wallet) => wallet.connectorType !== 'embedded');
  }, [wallets]);
};
