import { parseEther, parseGwei, parseUnits } from 'viem/utils';
import type { Evm } from './types';

export const MAX_BYTES =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff' as Evm.Word;

export const NULL_BYTES =
  '0x0000000000000000000000000000000000000000000000000000000000000000' as Evm.Word;

export const NULL_ADDRESS =
  '0x0000000000000000000000000000000000000000' as Evm.Address;
export const DUMMY_ADDRESS =
  '0x000000000000000000000000000000000000deaf' as Evm.Address;
export const DEAD_ADDRESS =
  '0x000000000000000000000000000000000000dead' as Evm.Address;

export const ONE_TOKEN_IN_BASE_UNITS = parseEther('1');
export const ONE_GWEI = parseGwei('1');

export const ETH_UNITS_DECIMALS = {
  wei: 0,
  mwei: 6,
  gwei: 9,
  ether: 18,
} as const;

export const ONE_MWEI = parseUnits('1', ETH_UNITS_DECIMALS.mwei);

export const ZERO = 0n;

export const ONE_MIN_IN_SECONDS = 60;
export const ONE_HOUR_IN_SECONDS = ONE_MIN_IN_SECONDS * 60;

export * from './constants';
export * from './party';
export * from './types';
export * from './user';
export * from './utils/';
export * from './zod';
