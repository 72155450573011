import { QueryClient } from '@tanstack/react-query';
import { createIDBPersister } from '../utils/query/persister';
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1_000 * 60 * 60 * 24, // 24 hours
      networkMode: 'offlineFirst',
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

export const persister = createIDBPersister('pob.studio.idb.cache');
