import { match } from 'ts-pattern';
import type { UnwrapTagged } from 'type-fest';
import {
  hexToBytes,
  hexToNumber,
  hexToString,
  keccak256,
  numberToHex,
  pad,
  slice,
  stringToHex,
} from 'viem/utils';
import { z } from 'zod';
import type { HexString } from '../types';
import { combineBytes } from '../utils/bytes';
import {
  ERC725_METHOD_SIGNATURE_TO_METHOD,
  ERC725_VERIFIED_URL_METHODS,
  type ERC725VerificationMethod,
  type ERC725VerificationMethodSignature,
} from './constants';
export const VERIFIED_URL_HEADER = '0x0000';

export const verificationMethodSignatureZod = z.nativeEnum(
  ERC725_VERIFIED_URL_METHODS,
);

export const decodeVerifiedUrl = (
  value: HexString,
): {
  verificationMethodSignature: ERC725VerificationMethodSignature | HexString;
  verificationMethod: ERC725VerificationMethod | null;
  verificationDataHash: HexString;
  sourceUrl: string;
} => {
  const header = slice(value, 0, 2);
  const isHeaderValid = header === VERIFIED_URL_HEADER;
  if (!isHeaderValid) {
    throw new Error('Invalid header');
  }
  const verificationMethodSignatureRaw = slice(value, 2, 6);
  const verificationMethodSignatureResult =
    verificationMethodSignatureZod.safeParse(verificationMethodSignatureRaw);
  const verificationMethod = verificationMethodSignatureResult.success
    ? ERC725_METHOD_SIGNATURE_TO_METHOD[
        verificationMethodSignatureResult.data as UnwrapTagged<
          typeof verificationMethodSignatureResult.data
        >
      ]
    : null;

  const encodedVerificationDataLength = slice(value, 6, 8);
  const verificationDataLength = hexToNumber(encodedVerificationDataLength);
  const verificationDataHash = slice(value, 8, 8 + verificationDataLength);
  const dataSource = slice(value, 8 + verificationDataLength);
  const dataSourceAsString = hexToString(dataSource);

  const decodedDataSourceAsString = decodeURIComponent(dataSourceAsString);

  return {
    verificationMethodSignature: verificationMethodSignatureResult.success
      ? verificationMethodSignatureResult.data
      : verificationMethodSignatureRaw,
    verificationMethod,
    verificationDataHash,
    sourceUrl: decodedDataSourceAsString,
  };
  // if (value.slice(0, 6) === '0x0000') {
  //   // DEAL with VerifiableURI
  //   // NOTE: A JSONURL with a 0x00000000 verification method is invalid.

  //   /*
  //     0        1         2         3         4         5         6         7         8
  //     12345678901234567890123456789012345678901234567890123456789012345678901234567890
  //     0x0000 code
  //           6f357c6a hash fn [6]
  //                   0020 data len [14]
  //                       820464ddfac1be...[18 + data len]
  //                                                      [18 + data len]...696670733a2f2...[...rest]
  //   */
  //   const verificationMethodSignature = `0x${value.slice(6, 14)}`;
  //   // NOTE: verificationMethodSignature can be 0x00000000 if no verification method is used
  //   // this means that an invalid verification method should still return all data
  //   // and not be an error. It's up to the method calling this to figure out
  //   // whether an unknown verification method is an error or not.
  //   const verificationMethod = getVerificationMethod(
  //     verificationMethodSignature,
  //   );
  //   const encodedLength = `0x${value.slice(14, 18)}`; // Rest of data string after function hash
  //   const dataLength = hexToNumber(encodedLength) as number;
  //   const dataHash = `0x${value.slice(18, 18 + dataLength * 2)}`; // Get jsonHash 32 bytes
  //   const dataSource = hexToUtf8(`0x${value.slice(18 + dataLength * 2)}`); // Get remainder as URI

  //   return {
  //     verification: {
  //       method: verificationMethod?.name || verificationMethodSignature,
  //       data: dataHash,
  //     },
  //     url: dataSource,
  //   };
  // }

  // // @Deprecated code here:

  // // Eventually we should no longer have JSONURL, AssetURL or (bytes4,URI)

  // // DEAL with JSONURL

  // const verificationMethodSignature = value.slice(0, 10);
  // const verificationMethod = getVerificationMethod(verificationMethodSignature);
  // const encodedData = value.slice(10); // Rest of data string after function hash

  // try {
  //   // Special case where JSONURL is really (bytes4,URI) as specified
  //   // by the old version of LSP8TokenMetadataBaseURI
  //   // Catch error in case the buffor is not convertable to utf8.
  //   const dataSource = hexToUtf8(`0x${encodedData}`); // Get as URI
  //   if (encodedData.length < 64 || /^[a-z]{2,}:[/\S]/.test(dataSource)) {
  //     // If the verification data starts with a utf8 sequence that looks like https:/ or data: or ar:/ for example.
  //     return {
  //       verification: {
  //         method: NONE_VERIFICATION_METHOD,
  //         data: '0x',
  //       },
  //       url: dataSource,
  //     };
  //   }
  // } catch {
  //   // ignore
  // }

  // const dataHash = `0x${encodedData.slice(0, 64)}`; // Get jsonHash 32 bytes
  // const dataSource = hexToUtf8(`0x${encodedData.slice(64)}`); // Get remainder as URI

  // return {
  //   verification: {
  //     method: verificationMethod?.name || verificationMethodSignature,
  //     data: dataHash,
  //   },
  //   url: dataSource,
  // };
};

export const encodeVerifiedUrl = (
  sourceUrl: string,
  sourceContent: string,
  verificationMethod: ERC725VerificationMethod,
) => {
  const verificationDataHash = match(verificationMethod)
    .with('keccak256(utf8)', () => {
      return keccak256(stringToHex(sourceContent));
    })
    .with('keccak256(bytes)', () => {
      throw new Error('keccak256(bytes) verification not supported');
    })
    .with('ecdsa', () => {
      throw new Error('ECDSA verification not supported');
    })
    .exhaustive();

  const encodedVerificationDataLength = pad(
    numberToHex(hexToBytes(verificationDataHash).length),
    {
      size: 2,
    },
  );

  console.log('encodedVerificationDataLength: ', encodedVerificationDataLength);

  return combineBytes(
    VERIFIED_URL_HEADER,
    ERC725_VERIFIED_URL_METHODS[verificationMethod],
    encodedVerificationDataLength,
    verificationDataHash,
    stringToHex(sourceUrl),
  );
};

// export function getVerificationMethod(nameOrSig: string):
//   | {
//       method: (data: string | object | Uint8Array | null) => string;
//       name: SUPPORTED_VERIFICATION_METHOD_STRINGS;
//       sig: SUPPORTED_VERIFICATION_METHODS;
//     }
//   | undefined {
//   const verificationMethod = Object.values(HASH_METHODS).find(
//     ({ name, sig }) => name === nameOrSig || sig === nameOrSig,
//   );

//   if (!verificationMethod && nameOrSig !== '0x00000000') {
//     throw new Error(
//       `Chosen verification method '${nameOrSig}' is not supported. Supported verification methods: ${SUPPORTED_VERIFICATION_METHODS_LIST}`,
//     );
//   }

//   return verificationMethod;
// }

export const verifyUrl = (
  dataContent: string,
  method: ERC725VerificationMethod,
  dataHash: HexString,
) => {
  return match(method)
    .with('keccak256(utf8)', () => {
      // console.log('dataHash from dataContent: ', keccak256(stringToHex(dataContent)));
      return dataHash === keccak256(stringToHex(dataContent));
    })
    .with('keccak256(bytes)', () => {
      throw new Error('keccak256(bytes) verification not supported');
    })
    .with('ecdsa', () => {
      throw new Error('ECDSA verification not supported');
    })
    .exhaustive();
};
