import { NULL_ADDRESS } from '@pob/shared';
import React, { createContext, useContext, useMemo } from 'react';
import { mainnet } from 'viem/chains';
import { trpc } from '~src/clients/trpc-client';
import { useSyncStateAcrossTabs } from '~src/hooks/state/useSyncStateAcrossTabs';
import { useBestWalletForPrivyUser } from '~src/hooks/wallet/useWalletFromPrivy';
import { useCart } from '~src/shared-apps/cart/stores';
import { useSearchSettings } from '~src/shared-apps/cmd/stores/settings';
import { useBlockverseContextStore } from '~src/shared-apps/context/stores';
type BlockverseContextType = {
  isClaimable: boolean | undefined;
};

export const BlockverseContext = createContext<BlockverseContextType>({
  isClaimable: undefined,
});

export type BlockverseProviderProps = {};

export const useBlockverseContext = () => {
  const context = useContext(BlockverseContext);
  if (!context) {
    throw new Error(
      'useBlockverseContext must be used within a BlockverseContext',
    );
  }
  return context;
};

const updateStores = () => {
  useCart.persist.rehydrate();
  useSearchSettings.persist.rehydrate();
  useBlockverseContextStore.persist.rehydrate();
};

export const BlockverseProvider = ({
  children,
}: BlockverseProviderProps & { children: React.ReactNode }) => {
  useSyncStateAcrossTabs(updateStores);

  // check balance of hash for claimable status
  const wallet = useBestWalletForPrivyUser();
  const hiraethClaimableStatusRef = trpc.hiraeth.global.claimable.useQuery(
    { addressOrName: wallet?.address ?? NULL_ADDRESS, chainId: mainnet.id },
    {
      enabled: !!wallet?.address,
    },
  );

  const state = useMemo(() => {
    return {
      isClaimable: hiraethClaimableStatusRef.data?.isClaimable,
    };
  }, [hiraethClaimableStatusRef]);

  return (
    <BlockverseContext.Provider value={state}>
      {children}
    </BlockverseContext.Provider>
  );
};
