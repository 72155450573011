export const mapEntries = <
  O extends Record<string | number | symbol, any>,
  K extends keyof O,
  V extends O[K],
  NK extends string | number | symbol,
  N extends any,
>(
  obj: O,
  fn: (entry: [K, V]) => [NK, N],
) => {
  const entries = Object.entries(obj) as [K, V][];
  return Object.fromEntries(entries.map(fn)) as unknown as Record<NK, N>;
};
