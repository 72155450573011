import { pad, sliceHex, toHex } from 'viem';
import { z } from 'zod';
import type { LSP } from '../types';
import { combineBytes } from '../utils/bytes';
import { getERC725DataKey } from './getERC725DataKey';

/**
 * Gets the data key for an array element in an ERC725 array
 * The key consists of:
 * - First 16 bytes: bytes16(keccak256(KeyName))
 * - Last 16 bytes: bytes16(uint128(ArrayElementIndex))
 * @param key The array key in format "keyName[index]"
 * @returns The 32-byte data key for the array element
 */

export const getERC725ArrayDataKey = (key: `${string}[]`, len: number = 0) => {
  const safeLen = z.number().int().nonnegative().parse(len);
  const rootDataKey = getERC725DataKey<LSP.ERC725ArrayDataKey>(key);

  const rootDataKeyValue = pad(toHex(safeLen), {
    size: 16,
  });
  // Get first 16 bytes of root key
  const keyPrefix = sliceHex(rootDataKey, 0, 16);

  const elementDataKeys: LSP.ERC725ArrayElementDataKey[] = [];
  // Convert index to 16 byte hex
  for (let i = 0; i < safeLen; i++) {
    const indexHex = pad(toHex(i), {
      size: 16,
      dir: 'left',
    });
    elementDataKeys.push(
      combineBytes(keyPrefix, indexHex) as LSP.ERC725ArrayElementDataKey,
    );
  }

  return {
    rootDataKey,
    rootDataKeyValue,
    elementDataKeys,
  };
};
