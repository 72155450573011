import { vercel } from '@t3-oss/env-core/presets-zod';
import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';
import { kv } from './kv';
import { postgres } from './postgres';
import { qStash } from './qstash';
export const env = createEnv({
  server: {
    // DATABASE_URL: z.string().url(),
    // OPEN_AI_API_KEY: z.string().min(1),
    ETHERSCAN_MAINNET_API_KEY: z.string().min(1),
    MORALIS_API_KEY: z.string().min(1),
    FLIPSIDE_API_KEY: z.string().min(1),
    DUNE_API_KEY: z.string().min(1),
    ARWEAVE_STORAGE_PRIVATE_KEY: z.string().min(1),
    NEYNAR_API_KEY: z.string().min(1),
    SLACK_BOT_TOKEN: z.string().min(1),
    SLACK_SIGNING_SECRET: z.string().min(1),
    HISTORY_AGENT_BOT_SIGNER_UUID: z.string().min(1),
    CRON_SECRET: z.string().min(1),
    PRIVY_VERIFICATION_KEY: z.string().min(1),
    PRIVY_APP_SECRET: z.string().min(1),
    PRIVY_VERIFICATION_ISSUER: z.string().min(1),
    PINATA_JWT: z.string().min(1),
    // HIGHLIGHT_SOURCEMAP_UPLOAD_API_KEY: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_MAINNET_ALCHEMY_KEY: z.string().min(1),
    // NEXT_PUBLIC_TESTNET_ALCHEMY_KEY: z.string().min(1),
    NEXT_PUBLIC_SIMPLE_HASH_API_KEY: z.string().min(1),

    NEXT_PUBLIC_NEYNAR_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_PRIVY_APP_ID: z.string().min(1),

    NEXT_PUBLIC_PINATA_ACCESS_KEY: z.string().min(1),

    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID: z.string().min(1),
  },
  // If you're using Next.js < 13.4.4, you'll need to specify the runtimeEnv manually
  // runtimeEnv: {
  //   DATABASE_URL: process.env.DATABASE_URL,
  //   OPEN_AI_API_KEY: process.env.OPEN_AI_API_KEY,
  // },
  // For Next.js >= 13.4.4, you can just reference process.env:
  experimental__runtimeEnv: {
    ...process.env,
    NEXT_PUBLIC_MAINNET_ALCHEMY_KEY:
      process.env.NEXT_PUBLIC_MAINNET_ALCHEMY_KEY,
    // NEXT_PUBLIC_TESTNET_ALCHEMY_KEY: process.env.NEXT_PUBLIC_TESTNET_ALCHEMY_KEY,
    NEXT_PUBLIC_SIMPLE_HASH_API_KEY:
      process.env.NEXT_PUBLIC_SIMPLE_HASH_API_KEY,

    NEXT_PUBLIC_NEYNAR_CLIENT_ID: process.env.NEXT_PUBLIC_NEYNAR_CLIENT_ID,
    NEXT_PUBLIC_PRIVY_APP_ID: process.env.NEXT_PUBLIC_PRIVY_APP_ID,

    NEXT_PUBLIC_PINATA_ACCESS_KEY: process.env.NEXT_PUBLIC_PINATA_ACCESS_KEY,
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID:
      process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID,
  },
  extends: [vercel(), postgres, qStash, kv],
});
