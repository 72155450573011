import type { BlockverseProjectId } from '@pob/blockverse';
import { produce } from 'immer';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IS_PROD } from '~src/constants';
import { serializeId } from '../../../utils/strings/serializeId';
import { HashedBlockverseContext, type BlockverseContextId } from '../types';
import { ContextTemporalMetadata } from '../types/context';
import { getBlockverseObjIdFromContextId } from '../utils/getBlockverseObjIdFromContextId';

const BLOCKVERSE_CONTEXT_VERSION = '3.0.0';
const BLOCKVERSE_CONTEXT_NAME = `blockverse-context-${BLOCKVERSE_CONTEXT_VERSION}`;

export type ContextTemporalMetadataForStore = ContextTemporalMetadata & {
  dateAdded: number;
};

export type BlockverseContextStoreData = {
  contexts: {
    [contextId: string]: HashedBlockverseContext;
  };
  blockverseObjectToContexts: {
    [objId: string]: {
      [contextId: string]: true;
    };
  };
};

export type BlockverseContextStoreModifiers = {
  // add, remove, update
  updateContext: <E extends BlockverseProjectId>(
    contextId: BlockverseContextId<E>,
    context: HashedBlockverseContext,
  ) => void;
  // removeContext: BlockverseContextModifier<
  //   (id: string, contextId: string) => void
  // >;
};

export type BlockverseContextStore = BlockverseContextStoreData &
  BlockverseContextStoreModifiers;

export const useBlockverseContextStore = create<BlockverseContextStore>()(
  persist(
    (set, get) => ({
      contexts: {},
      blockverseObjectToContexts: {},
      updateContext: <E extends BlockverseProjectId>(
        contextId: BlockverseContextId<E>,
        context: HashedBlockverseContext,
      ) => {
        const serializedContextId = serializeId(contextId);

        const objId = getBlockverseObjIdFromContextId(contextId);
        const serializedObjId = serializeId(objId);

        const finalizedTemporalParams = context.temporal ?? {
          dateUpdated: Date.now(),
          ttl: null,
        };

        set(
          produce((u) => {
            const isNewContext = !u.contexts[serializedContextId];
            if (isNewContext) {
              u.contexts[serializedContextId] = context;
            } else if (u.contexts[serializedContextId].temporal.ttl === null) {
              // console.log('context already exists, skipping');
              return;
            }

            u.contexts[serializedContextId].temporal = finalizedTemporalParams;

            // if (!!u.temporalMetadata[serializedContextKey]) {
            //   u.temporalMetadata[serializedContextKey].dateUpdated = finalizedTemporalParams.dateUpdated;
            //   u.temporalMetadata[serializedContextKey].ttl = finalizedTemporalParams.ttl;
            // } else {
            //   u.temporalMetadata[serializedContextKey] = {
            //     dateAdded: finalizedTemporalParams.dateUpdated,
            //     dateUpdated: finalizedTemporalParams.dateUpdated,
            //     ttl: finalizedTemporalParams.ttl,
            //   };
            // }

            if (!u.blockverseObjectToContexts[serializedObjId]) {
              u.blockverseObjectToContexts[serializedObjId] = {};
            }
            u.blockverseObjectToContexts[serializedObjId][serializedContextId] =
              true;

            if (!IS_PROD) {
              console.log('updated context', serializedContextId);
            }
          }),
        );
      },
      // removeContext:
      //   <E extends BlockverseProjectId>(
      //     project: E,
      //     chainId: SupportedBlockverseChainId<E>,
      //   ) =>
      //   (id: string, contextIds: string) => {
      //     assertIsAvailableChainId(chainId);
      //     set(
      //       produce((u) => {
      //         if (!u.contextMap[project]) {
      //           u.contextMap[project] = {};
      //         }
      //         if (!u.contextMap[project][chainId]) {
      //           u.contextMap[project][chainId] = {};
      //         }
      //         const contextProps = u.contextMap[project][chainId]![id] ?? {
      //           contexts: {},
      //         };
      //         for (const contextId of contextIds) {
      //           delete contextProps.contexts[contextId];
      //         }
      //         u.contextMap[project][chainId]![id] = contextProps;
      //       }),
      //     );
      //   },
    }),
    {
      name: BLOCKVERSE_CONTEXT_NAME,
    },
  ),
);
