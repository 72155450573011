'use client';
// import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from '@privy-io/wagmi';
import { PrivyProvider } from '~src/shared-apps/social/providers/Privy';
// import { WagmiProvider } from 'wagmi';
import { QueryClientProvider } from '@tanstack/react-query';
import { AppProps } from 'next/app';
import { FC, useEffect } from 'react';
import { queryClient } from '../src/clients/query';
import { trpc } from '../src/clients/trpc-client';
import { wagmiConfig } from '../src/clients/wagmi';
// import { Mdx } from '../components/mdx';
import { HighlightInit } from '@highlight-run/next/client';
import { Seo } from '../src/components/seo/default';

import '../src/styles/globals.css';

// import '@neynar/react/dist/style.css';
// import '@rainbow-me/rainbowkit/styles.css';
import dynamic from 'next/dynamic';
import 'react-activity/dist/library.css';
import { dummyTimeAgo } from '~src/clients/time-ago';
import { ROOT_URL, VERCEL_ENV } from '~src/constants';
import { HiraethWorkersProvider } from '~src/pages/hiraeth/txn/providers/HiraethWorkers';
import { BlockverseProvider } from '~src/shared-apps/blockverse/providers/Blockverse';
import { CmdProvider } from '~src/shared-apps/cmd/providers/Cmd';
import { HeaderProvider } from '~src/shared-apps/header/providers/HeaderProvider';
import { PrivyDialogsProvider } from '~src/shared-apps/social/providers/PrivyDialogsProvider';
import { logula } from '~src/utils/debug/consola';
import { env } from '~src/constants/env';
dummyTimeAgo.dummy();

// Lazy imports
const LazyCmdDialog = dynamic(
  () => import('~src/shared-apps/cmd').then((mod) => mod.CmdDialog),
  {
    ssr: false,
  },
);

const LazyToaster = dynamic(
  () => import('~src/components/toaster').then((mod) => mod.Toaster),
  {
    ssr: false,
  },
);

const LazyCmdProvider = dynamic(() =>
  import('~src/shared-apps/cmd/providers/Cmd').then((mod) => mod.CmdProvider),
);

const LazyPrivyManageDialog = dynamic(
  () =>
    import('~src/shared-apps/social/modals/privy/manage').then(
      (mod) => mod.PrivyManageDialog,
    ),
  {
    ssr: false,
  },
);

const App: FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    logula.withTag('pob').info('running in', VERCEL_ENV, ROOT_URL);
  }, []);
  return (
    <>
      <HighlightInit
        // excludedHostnames={['localhost']}
        projectId={env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
        serviceName="pob-dapp"
        tracingOrigins
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
        }}
        debug
      />
      <Seo />
      <PrivyProvider>
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            {/* <RainbowKitProvider modalSize="compact"> */}
            {/* <NeynarContextProvider settings={farcasterSettings}> */}
            <BlockverseProvider>
              <HiraethWorkersProvider>
                <CmdProvider>
                  <PrivyDialogsProvider>
                    <HeaderProvider>
                      <LazyToaster />
                      <LazyCmdDialog />
                      <LazyPrivyManageDialog />
                      {/* <AnimatePresence mode="wait" initial={false}> */}
                      <Component {...pageProps} />
                      {/* </AnimatePresence> */}
                    </HeaderProvider>
                  </PrivyDialogsProvider>
                </CmdProvider>
              </HiraethWorkersProvider>
            </BlockverseProvider>
            {/* </NeynarContextProvider> */}
            {/* </RainbowKitProvider> */}
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </>
  );
};

export default trpc.withTRPC(App);
