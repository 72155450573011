import { AvailableChainId, assertIsAvailableChainId } from '@protocol/chains';
import { produce } from 'immer';
import { base } from 'viem/chains';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { getBlockverseProjectDomainFromObjId } from '~src/shared-apps/context/utils/getBlockverseProjectDomainFromObjId';
import { serializeId } from '~src/utils/strings/serializeId';
import {
  SupportedCartProjectId,
  type SupportedCartChainId,
} from '../../../config/cart';

const BLOCKVERSE_CART_VERSION = '2.0.1';
const BLOCKVERSE_CART_NAME = `blockverse-cart-${BLOCKVERSE_CART_VERSION}`;

export interface CartItemState {
  isSavedForLater?: boolean;
}

export interface CartItem<
  P extends SupportedCartProjectId = SupportedCartProjectId,
> extends CartItemState {
  addedAt: Date | number;
  addedBy?: string;
  order: number;
  objId: BlockverseObjId<P>;
}

export type Cart<P extends SupportedCartProjectId> = Record<
  string,
  CartItem<P>
>;

export type CartDataState = {
  cart: Cart<SupportedCartProjectId>;
  cartNonce: Record<string, number>;
  currentChainId: AvailableChainId;
  currentProjectId: SupportedCartProjectId;
};

type UpdateCartStateParam = Partial<CartItemState>;

export type CartModifiers = {
  // add, remove, update
  clearCart: <P extends SupportedCartProjectId>(
    objIds: BlockverseObjId<P>[],
  ) => void;
  addToCart: <P extends SupportedCartProjectId>(
    objId: BlockverseObjId<P>,
    state: CartItemState,
    addedBy?: string,
  ) => void;
  reorderCart: <P extends SupportedCartProjectId>(
    objIds: BlockverseObjId<P>[],
  ) => void;
  removeFromCart: <P extends SupportedCartProjectId>(
    objId: BlockverseObjId<P>,
  ) => void;
  removeItemsFromCart: <P extends SupportedCartProjectId>(
    objIds: BlockverseObjId<P>[],
  ) => void;
  updateCartState: <P extends SupportedCartProjectId>(
    objId: BlockverseObjId<P>,
    flags: UpdateCartStateParam,
  ) => void;
  batchUpdateCartState: <P extends SupportedCartProjectId>(
    objIds: BlockverseObjId<P>[],
    flags: UpdateCartStateParam,
  ) => void;
  setCurrentChainAndProject: (
    chainId: AvailableChainId,
    projectId: SupportedCartProjectId,
  ) => void;
};

export type CartState = CartModifiers & CartDataState;

// const createDefaultCart = () => {
//   const cart: Partial<MultiProjectCart> = {};
//   // NOTE: Defaults use PROJECT_SUPPORTED_CART_CHAINS
//   for (const project of Object.keys(PROJECT_SUPPORTED_CART_CHAINS)) {
//     if (!isSupportedCartProjectId(project)) {
//       continue;
//     }
//     cart[project as SupportedCartProjectId] = {};
//     for (const chainId of Object.values(
//       PROJECT_SUPPORTED_CART_CHAINS[project as SupportedCartProjectId]!,
//     )) {
//       cart[project as SupportedCartProjectId]![chainId] = {};
//     }
//   }
//   return cart as MultiProjectCart;
// };

const ensureCartMapInitialized =
  <CartProject extends SupportedCartProjectId>(
    project: CartProject,
    chainId: SupportedCartChainId<CartProject>,
  ) =>
  (u: any) => {
    assertIsAvailableChainId(chainId);
    if (!u.cart[project]) {
      u.cart[project] = {};
    }
    if (!u.cart[project]![chainId]) {
      u.cart[project]![chainId] = {};
    }
  };

export const useCart = create<CartState>()(
  persist(
    (set, get) => ({
      currentChainId: base.id,
      currentProjectId: 'hiraeth',
      setCurrentChainAndProject: (
        chainId: AvailableChainId,
        projectId: SupportedCartProjectId,
      ) => {
        set(
          produce((u) => {
            u.currentChainId = chainId;
            u.currentProjectId = projectId;
          }),
        );
      },
      cart: {}, // createDefaultCart(),
      cartNonce: {},
      clearCart: <CartProject extends SupportedCartProjectId>(
        objIds: BlockverseObjId<CartProject>[],
      ) => {
        assertIsAvailableChainId(objIds[1]);
        // HACK to allow for type narrowing
        set(
          produce<CartState>((u) => {
            for (const id of objIds) {
              const serializedId = serializeId(id);
              delete u.cart[serializedId];
            }
          }),
        );
      },
      addToCart: <P extends SupportedCartProjectId>(
        objId: BlockverseObjId<P>,
        state: CartItemState,
        addedBy?: string,
      ) => {
        // assertIsAvailableChainId(chainId);
        set(
          produce((u) => {
            // ensureCartMapInitialized(project, chainId)(u);
            const serializedObjId = serializeId(objId);
            const item = u.cart[serializedObjId];
            if (!!item) {
              console.warn(
                `Item with id ${serializedObjId} already added to cart, ignoring.`,
              );
              return;
            }

            const projectDomain = getBlockverseProjectDomainFromObjId(objId);
            const serializedProjectDomain = serializeId(projectDomain);

            u.cart[serializedObjId] = {
              ...state,
              addedBy,
              objId,
              addedAt: Date.now(),
              order: u.cartNonce[serializedProjectDomain]++,
            };
          }),
        );
      },
      reorderCart: <P extends SupportedCartProjectId>(
        objIds: BlockverseObjId<P>[],
      ) => {
        set(
          produce((u) => {
            for (let i = 0; i < objIds.length; ++i) {
              const serializedObjId = serializeId(objIds[i]);
              if (!!u.cart[serializedObjId]) {
                u.cart[serializedObjId].order = i;
              } else {
                console.warn(
                  `Item with id ${objIds[i]} doesn't exist in cart, ignoring.`,
                );
              }
            }
          }),
        );
      },
      removeFromCart: <CartProject extends SupportedCartProjectId>(
        objId: BlockverseObjId<CartProject>,
      ) => {
        set(
          produce((u) => {
            const serializedObjId = serializeId(objId);
            if (!u.cart[serializedObjId]) {
              console.warn(
                `Item with id ${serializedObjId} doesn't exist in cart, ignoring.`,
              );
              return;
            }
            delete u.cart[serializedObjId];
          }),
        );
      },
      removeItemsFromCart: <CartProject extends SupportedCartProjectId>(
        objIds: BlockverseObjId<CartProject>[],
      ) => {
        set(
          produce((u) => {
            for (const objId of objIds) {
              const serializedObjId = serializeId(objId);
              if (!u.cart[serializedObjId]) {
                console.warn(
                  `Item with id ${serializedObjId} doesn't exist in cart, ignoring.`,
                );
                continue;
              }
              delete u.cart[serializedObjId];
            }
          }),
        );
      },
      updateCartState: <CartProject extends SupportedCartProjectId>(
        objId: BlockverseObjId<CartProject>,
        state: UpdateCartStateParam,
      ) => {
        const serializedObjId = serializeId(objId);
        const cart = get().cart;
        if (!cart[serializedObjId]) {
          console.warn(
            `Item with id ${serializedObjId} doesn't exist in cart, ignoring.`,
          );
          return;
        }
        set(
          produce((u) => {
            u.cart[serializedObjId] = {
              ...u.cart[serializedObjId],
              ...state,
            };
          }),
        );
      },
      batchUpdateCartState: <CartProject extends SupportedCartProjectId>(
        objIds: BlockverseObjId<CartProject>[],
        state: UpdateCartStateParam,
      ) => {
        set(
          produce((u) => {
            for (const objId of objIds) {
              const serializedObjId = serializeId(objId);
              if (!!u.cart[serializedObjId]) {
                u.cart[serializedObjId] = {
                  ...u.cart[serializedObjId],
                  ...state,
                };
              } else {
                console.warn(
                  `Item with id ${serializedObjId} doesn't exist in cart, ignoring.`,
                );
              }
            }
          }),
        );
      },
    }),
    { name: BLOCKVERSE_CART_NAME },
  ),
);
